import React from "react";
import * as d3 from "d3-shape";

const width = 300;
const height = 300;
const radius = 100;
const data = [

  {
    tag: "Community 35%",
    occurences: 35,
    color: "rgb(175, 240, 91)"
  },
  {
    tag: "algo.ski Treasury 30%",
    occurences: 25,
    color: "rgb(255, 94, 99)"
  },
  {
    tag: "Team 15%",
    occurences: 15,
    color: "rgb(26, 199, 194)"
  },
  {
    tag: "Marketing 10%",
    occurences: 10,
    color: "rgb(110, 64, 170)"
  },
  {
    tag: "Private Sale 10%",
    occurences: 15,
    color: "rgb(74, 64, 25)"
  },
  

];

const pie = d3.pie().value(d => d.occurences)(data);
const arc = d3
  .arc()
  .outerRadius(radius - 10)
  .innerRadius(40);
const outArc = d3
  .arc()
  .outerRadius(radius + 40)
  .innerRadius(radius - 10);
  

function PieChart() {
  return (
    <svg width={width + 100} height={height + 100}>
      <g transform={`translate(${width / 2},${height / 2})`}>
        {pie.map((d, index) => {
          const newWidth = 50;
          const x =
            outArc.centroid(d)[0] + newWidth / 2 > newWidth / 2
              ? newWidth / 2
              : -newWidth / 2;
          const textAnchor =
            outArc.centroid(d)[0] + newWidth / 2 > newWidth / 2
              ? { "text-anchor": "end" }
              : { "text-anchor": "start" };
          const y =
            outArc.centroid(d)[1] + newWidth / 2 > newWidth / 2
              ? newWidth / 2
              : 0;

          console.log(d);
          return (
            <g>
              <path d={arc(d)} fill={d.data.color} />
              {d.endAngle - d.startAngle > 0.5 && (
                <g>
                  <line
                    x1={arc.centroid(d)[0]}
                    y1={arc.centroid(d)[1]}
                    x2={outArc.centroid(d)[0]}
                    y2={outArc.centroid(d)[1]}
                    stroke={"white"}
                  />
                  {/* <line
                x1={outArc.centroid(d)[0]}
                y1={outArc.centroid(d)[1]}
                x2={x}
                y2={outArc.centroid(d)[1]}
                stroke={'grey'}
              /> */}
              
                  <text
                    {...textAnchor}
                    fill="white"
                    alignment-baseline="middle"
                    transform={`translate(${outArc.centroid(d)[0] +
                      x}, ${outArc.centroid(d)[1] + y})`}
                  >
                    {data[index]["tag"]}
                  </text>
                </g>
              )}
            </g>
          );
        })}
      </g>
    </svg>
  );
}
export default PieChart