import React, { Component, useEffect, useState } from "react";
import firebaseApp from "../firebase";
import { ReactSEOMetaTags } from "react-seo-meta-tags";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import Gallery from "../components/Gallery.tsx";
import Reaction from "../components/Reaction.tsx";
import InputCode from "../components/InputCode";
import "../components/phone.css";
import PhoneInput from "react-phone-number-input";
import us from "react-phone-number-input/";
import { useParams } from "react-router-dom";
import Pipeline from "@pipeline-ui-2/pipeline"; //change to import Pipeline from 'Pipeline for realtime editing Pipeline index.js, and dependency to: "Pipeline": "file:..",
import "bulma/css/bulma.min.css";
import "../main.css";
import {
  getAssetsOfAddress,
  getCollectionFromTokens,
  getDetailsOfAsset,
  getDetailsOfAssets,
} from "../lib/algohelpers.ts";
import { NFT } from "../lib/nft.ts";
import Tooltip from "../components/Tooltip";
import FileUploader from "react-firebase-file-uploader";
import { LinkForm, LinkList } from "../components/LinkForm";
import { LinkListDisplay } from "../components/LinkListDisplay";
import MDEdit from "../components/MDEdit";
import MDEditor from "@uiw/react-md-editor";

// const accountSid = process.env.TWILIO_ACCOUNT_SID;
// const authToken = process.env.TWILIO_AUTH_TOKEN;
// const client = require('twilio')(accountSid, authToken);

//change recipient address before sending transaction
const recipient = "KCZDQ2Y3OBDBW6NYGYDTOQ6GPW345H3EM2KYI3DPF54GZL4SKWNOQH2FHM";
const cryptrSecret = process.env.REACT_APP_CRYPTR_SECRET;
const myAlgoWallet = Pipeline.init();
const WalletConnect = Pipeline.init();

const Cryptr = require("cryptr");
const cryptr = new Cryptr(`${cryptrSecret}`);

//amount in micro Algos
const amount = 0;

const note = "red";

//0 = Algorand, otherwise index number of asset
const index = 0;

//set to false to switch to TestNet
Pipeline.main = true;

var mynet = Pipeline.main ? "MainNet" : "TestNet";

class Profile extends React.Component {
  state = {
    myAddress: "Connect your wallet",
    color: "none",
    balance: "n/a",
    username: "",
    phone: "",
    smsNum: "",
    displayImage: "",
    staff: "nope",
    progress: 0,
    isUploading: false,
    verifyError: false,
    blur: false,
    verifyResponse: "",
    isVerified: "",
    coverImage: "",
    users: "",
    bio: "",
    isModal: false,
    isModalGallery: false,
    isModalProf: false,
    isModalVerify: false,
    verifyInput: "",
    algoAddress: this.props.params.username,
    ivoted: false,
    ivotedPeriod2: false,
    linksList: [],
    quizActive: true,
    loading: false,
    setLoading: false,
    currentQuiz: 0,
    currentQuestion: 0,
    currentAnswers: [],
    attemptedQuestions: 0,
    score: 0,
    quizzes: [],
    postChild: false,
    posts: [
      {
        post: {
          content: "",
          timestamp: "",
        },
      },
    ],
  };

  verifySMS = () => {
    var axios = require("axios");

    var config = {
      method: "post",
      url:
        "https://verify-sample-5247-dev.twil.io/start-verify?to=%2B" +
        this.state.phone +
        "&locale=en&channel=sms",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + process.env.REACT_APP_TWILIO_AUTH_TOKEN,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
    this.setState({ loading: true });
  };

  checkVerifySMS = (code) => {
    var axios = require("axios");

    var config = {
      method: "post",
      url:
        "https://verify-sample-5247-dev.twil.io/check-verify?to=%2B" +
        this.state.phone +
        "&verification_code=" +
        code,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + process.env.REACT_APP_TWILIO_AUTH_TOKEN,
      },
    };

    axios(config)
      .then((response) => {
        console.log(response);
        const res = response.data;
        const verifiedStatus = res.success;
        this.setState({ verifySuccess: verifiedStatus });
        if (verifiedStatus) {
          this.updateVerify();
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ verifyError: true });
        setTimeout(() => this.setState({ verifyError: false }), 4000);
      });
    // .then(function (response) {
    //   console.log(JSON.stringify(response.data));
    //   if (response.data) {
    //   this.setState({verifyResponse: response.data})
    // }
    // })
    // .catch(function (error) {
    //   console.log(error);
    //   if (error) {
    //   this.setState({verifyError: true});
    //   setTimeout(() => this.setState({verifyError : false}), 2000);
    // }});
  };

  getGov = () => {
    var axios = require("axios");

    var config = {
      method: "GET",
      url:
        "https://governance.algorand.foundation/api/periods/governance-period-1/governors/" +
        this.state.algoAddress +
        "/activities/",
    };

    axios(config)
      .then((response) => {
        const govdata = response.data;
        const ivoted = govdata.count;
        if (ivoted > 0) {
          this.setState({ ivoted: true });
        }
      })
      .catch((error) => {
        console.log("Not a governor");
      });
  };

  getGovPeriod2 = () => {
    var axios = require("axios");

    var config = {
      method: "GET",
      url:
        "https://governance.algorand.foundation/api/periods/governance-period-2/governors/" +
        this.state.algoAddress +
        "/activities/",
    };

    axios(config)
      .then((response) => {
        const govdata = response.data;
        const ivoted = govdata.count;
        if (ivoted > 0) {
          this.setState({ ivotedPeriod2: true });
        }
      })
      .catch((error) => {
        console.log("Not a period 2 governor");
      });
  };

  handleVerifyInput = (event) =>
    this.setState({ verifyInput: event.target.value });

  addLinkToState = (linkSite, linkUrl) => {
    let toBeAddedActionItem = {
      linkSite: linkSite,
      linkUrl: linkUrl,
    };
    if (this.prevState) {
      this.setState((prevState) => ({
        linksList: prevState.linksList.concat(toBeAddedActionItem),
      }));
    }
  };
  deleteActionItemFromState = (index) => {
    const linksList = [...this.state.linksList];
    linksList.splice(index, 1);
    this.setState({ linksList });
  };

  handleChangeUsername = (event) =>
    this.setState({ username: event.target.value });
  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
  handleProgress = (progress) => this.setState({ progress });
  handleUploadError = (error) => {
    this.setState({ isUploading: false });
    console.error(error);
  };
  handleUploadSuccess = (filename) => {
    this.setState({ avatar: filename, progress: 100, isUploading: false });
    firebaseApp
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL()
      .then((url) => this.setState({ displayImage: url }));
  };

  handleUploadStartCover = () =>
    this.setState({ isUploading: true, progress: 0 });
  handleProgressCover = (progress) => this.setState({ progress });
  handleUploadErrorCover = (error) => {
    this.setState({ isUploading: false });
    console.error(error);
  };
  handleUploadSuccessCover = (filename) => {
    this.setState({ avatar: filename, progress: 100, isUploading: false });
    firebaseApp
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL()
      .then((url) => this.setState({ coverImage: url }));
  };

  dbRef = firebaseApp.database();
  updateDB = this.updateDB.bind(this);

  updateDB() {
    const myAddress = this.state.algoAddress;
    const username = this.state.username;
    const bio = this.state.bio;
    const coverImage =
      this.state.coverImage ??
      "https://9cover.com/images/ccovers/1362683988ski-in-the-air-abstract.jpg";
    const links = this.state.linksList ?? null;
    const displayImage =
      this.state.displayImage ??
      "https://firebasestorage.googleapis.com/v0/b/algrof-f67fb.appspot.com/o/blank-prof.jpg?alt=media&token=a7e4511d-5f91-4010-8a20-0604ee3af6d2";
    if (links) {
      this.dbRef
        .ref("/users/")
        .child(myAddress)
        .update({ myAddress, username, bio, coverImage, displayImage });
    } else {
      this.dbRef
        .ref("/users/")
        .child(myAddress)
        .update({ myAddress, username, bio, coverImage, displayImage, links });
    }
  }

  updateAddy() {
    const myAddress = this.state.algoAddress;
    this.dbRef.ref("/users/").child(myAddress).update({ myAddress });
  }

  updateVerify() {
    const myAddress = this.state.algoAddress;
    this.dbRef
      .ref("/users/")
      .child(myAddress)
      .update({
        isVerified: "yes",
        verifiedSMS: cryptr.encrypt(this.state.phone),
      });
    setTimeout(
      () => this.setState({ isModalVerify: !this.state.isModalVerify }),
      2000
    );
  }

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     net: mynet,
  //     txID: "",
  //     myAddress: "",
  //     balance: 0,
  //     color: "color"
  //   };
  // }

  fetchBalance = () => {
    Pipeline.balance(this.state.myAddress).then((data) => {
      this.setState({ balance: data });
    });
  };

  handleConnect = () => {
    Pipeline.pipeConnector = "myAlgoWallet";
    Pipeline.connect(myAlgoWallet).then((data) => {
      this.setState({ myAddress: data });
      localStorage.setItem("myAddress", data);
      this.updateAddy();
      window.location.href = "/u/" + this.state.myAddress;
    });
  };

  handleConnectOfficial = () => {
    Pipeline.pipeConnector = "WalletConnect";
    Pipeline.connect(WalletConnect).then((data) => {
      this.setState({ myAddress: data });
      localStorage.setItem("myAddress", data);
      this.updateAddy();
      window.location.href = "/u/" + this.state.myAddress;
    });
  };

  generateRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  handleSend = () => {
    Pipeline.send(
      recipient,
      amount,
      note,
      this.state.myAddress,
      myAlgoWallet,
      index
    ).then((data) => {
      this.setState({ txID: data });
    });
  };

  toggleChild = () => {
    this.setState((prevState) => ({
      postChild: !prevState.postChild,
    }));
  };

  Child = ({ toggleChild }) => {
    return (
      <div>
        <input className="input is-rounded" />
        <div className="buttons mt-2">
          <button className="button mt-2 is-small is-primary">Post</button>
        </div>
      </div>
    );
  };

  switchConnector = (event) => {
    Pipeline.pipeConnector = event.target.value;
    console.log(Pipeline.pipeConnector);
  };

  colorPicker = (event) => {
    this.setState({ color: event.target.value });
    console.log(event.target.value);
  };

  getVerifyStatus = () => {
    let ref = firebaseApp.database().ref("/users/" + this.state.algoAddress);
    ref.on("value", (snapshot) => {
      const users = snapshot.val();
      if (users) {
        this.setState({
          isVerified: users.isVerified,
        });
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isVerified !== this.state.isVerified) {
      this.getVerifyStatus();
    }
  }

  componentDidMount() {
    let ref = firebaseApp.database().ref("/users/" + this.state.algoAddress);
    ref.on("value", (snapshot) => {
      const users = snapshot.val();
      if (users) {
        this.setState({
          users: true,
          bio: users.bio,
          username: users.username,
          displayImage: users.displayImage,
          coverImage: users.coverImage,
          staff: users.staff,
          linksList: users.links,
          posts: users.posts,
          isVerified: users.isVerified,
        });
      }

      const { id } = this.props.params;
      this.getGov();
      this.getGovPeriod2();
    });
  }

  clearStorage = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  handleClick = () => {
    this.setState({ isModal: !this.state.isModal });
  };

  setBlur = () => {
    this.setState({blur: true});
    setTimeout(() => this.setState({ blur: false }), 2000);
  };

  handleClickProf = () => {
    this.setState({ isModalProf: !this.state.isModalProf });
  };

  handleClickGallery = () => {
    this.setState({ isModalGallery: !this.state.isModalGallery });
  };

  handleChangePhone = (e) => this.setState({ phone: e.target.value });

  handleClickVerify = () => {
    this.setState({ isModalVerify: !this.state.isModalVerify });
    this.setState({ loading: false });
  };

  handleUsernameChange = (e) => this.setState({ username: e.target.value });
  handleBioChange = (e) => this.setState({ bio: e.target.value });

  render() {
    const meta = {
      title: "Some Meta Title",
      description: "I am a description, and I can create multiple tags",
      canonical: "http://example.com/path/to/page",
      meta: {
        charset: "utf-8",
        name: {
          keywords: "react,meta,document,html,tags",
        },
      },
    };
    
    // const [uploadedImage, setUploadedImage] = useState(null);
    // const [imageSrc, setImageSrc] = useState("");

    // const handleUpload = async () => {
    //   const formData = new FormData();
    //   formData.append("file", uploadedImage);
    //   formData.append("upload_preset", "xuit9n89");

    //   const res = await axios.post("https://api.cloudinary.com/v1_1/dgg6qf2qy/image/upload", formData);
    //   // store image url in db.
    //   setImageSrc(res.data.url);
    // }

    // this.response.data.map(group => Object.keys(group.price).map(priceOpt => console.log(priceOpt, group.price[priceOpt])));

    const active = this.state.isModal ? "is-active" : "";
    const activeProf = this.state.isModalProf ? "is-active" : "";
    const activeGallery = this.state.isModalGallery ? "is-active" : "";
    const activeVerify = this.state.isModalVerify ? "is-active" : "";

    var verifiedSymbol;
    if (this.state.isVerified === "yes") {
      verifiedSymbol = (
        <span className="button has-text-weight-semibold is-static is-small is-primary is-outlined letterspacing">
          <img style={{width:"14px",marginRight:"5px"}} src="https://firebasestorage.googleapis.com/v0/b/algrof-f67fb.appspot.com/o/ski-logo-print.png?alt=media&token=bdf510d9-a101-49f7-92bd-f57fa30e1936"/> VERIFIED
        </span>
      );
    }

    var verifyIt;
    if (
      this.state.isVerified !== "yes" &&
      this.state.algoAddress === localStorage.getItem("myAddress")
    ) {
      verifyIt = (
        <span
          className="button is-small has-text-black is-primary"
          onClick={this.handleClickVerify}
        >
          Verify your account
        </span>
      );
    }

    var connected;
    if (localStorage.getItem("myAddress") === null) {
      connected = (
        <button
          onClick={this.handleClick}
          style={{ minWidth: "300px" }}
          className="is-fullwidth button is-rounded is-primary has-text-black"
        >
          Connect your wallet
        </button>
      );
    } else if (localStorage.getItem("myAddress") === this.state.algoAddress) {
      connected = (
        <div>
          <div className="field has-addons">
            <span className="control">
              <button
                className="button is-small is-rounded is-primary has-text-black is-static"
                style={{
                  backgroundColor: "turquoise",
                  border: "1px solid turquoise",
                }}
              >
                🟢 connected
              </button>
            </span>
            {/* <span className="control">
        {shouldVerify}</span> */}
            <span className="control">
              <p
                className="button is-small is-clickable is-dark is-rounded"
                onClick={this.clearStorage}
              >
                sign out
              </p>
            </span>
          </div>
          <div>
            <p className="subtitle is-6">
              {this.state.isVerified !== "yes" ? verifiedSymbol : ""}
            </p>
          </div>
        </div>
      );
    }
    var isConnected;
    if (this.state.myAddress === "Connect your wallet") {
      isConnected = (
        <p className="subtitle is-6 mb-1 has-text-grey-light">
          {this.state.algoAddress}
        </p>
      );
    } else {
      isConnected = (
        <p className="subtitle is-6 mb-1 has-text-grey-light">
          {this.state.myAddress}
        </p>
      );
    }

    var isOwnedWallet;
    if (this.state.myAddress === this.state.algoAddress) {
      isOwnedWallet = (
        <span
          className="button is-text ml-2 level-item"
          onClick={this.handleClickProf}
        >
          {" "}
          edit profile
        </span>
      );
    } else if (localStorage.getItem("myAddress") === this.state.algoAddress) {
      isOwnedWallet = "";
    } else if (localStorage.getItem("myAddress") === null) {
      isOwnedWallet = (
        <span
          className="ml-2 button is-text is-primary level-item has-text-white"
          onClick={this.handleClick}
        >
          {" "}
          connect wallet to edit
        </span>
      );
    }

    var cover;
    if (this.state.coverImage) {
      cover = `url("${this.state.coverImage}")`;
    } else {
      // cover = `url("https://picsum.photos/1000/400/?blur=5")`;
      cover = null;
    }

    var hasLinksDisplay;
    if (this.state.linksList !== undefined) {
      hasLinksDisplay = (
        <LinkListDisplay
          actionItemsList={this.state.linksList}
          deleteActionItemFromState={this.deleteActionItemFromState}
        />
      );
    }

    var hasLinks;
    if (this.state.linksList) {
      hasLinks = (
        <LinkList
          actionItemsList={this.state.linksList}
          deleteActionItemFromState={this.deleteActionItemFromState}
        />
      );
    }

    var hasPosts;
    if (this.state.posts) {
      hasPosts = Object.entries(this.state.posts).map(([key, post]) => {
        const postKey = key;
        console.log(postKey, post);

        return (
          <div key={postKey}>
            <ul>
              <div className="card mb-4">
                <header class="card-header">
                  <div className="card-header-title">
                    <figure className="image is-24x24">
                      <img
                        className="border is-rounded"
                        alt="Avatar"
                        src={
                          this.state.displayImage
                            ? this.state.displayImage
                            : "https://firebasestorage.googleapis.com/v0/b/algrof-f67fb.appspot.com/o/blank-prof.jpg?alt=media&token=a7e4511d-5f91-4010-8a20-0604ee3af6d2"
                        }
                      />
                    </figure>
                    <p className="ml-2">{this.state.username}</p>
                    <p className="ml-4 subtitle is-7">{post.timestamp}</p>
                  </div>
                  <button class="card-header-icon" aria-label="more options">
                    <span class="icon">
                      <i class="fas fa-angle-down" aria-hidden="true"></i>
                    </span>
                  </button>
                </header>
                <div className="card-content">
                  <MDEditor.Markdown source={post.content} />
                </div>
                <footer class="card-footer">
                  <a href="#" className="level-left card-footer-item">
                    <Reaction />
                  </a>
                  <span
                    onClick={this.toggleChild}
                    className="card-footer-item level-left"
                  >
                    <a href="#">Reply</a>
                  </span>
                </footer>
                {this.state.postChild === false ? (
                  ""
                ) : (
                  <div className="p-4">
                    <div class="control has-icons-left has-icons-right">
                      <input
                        class="input is-small"
                        type="text"
                        placeholder={"reply to " + this.state.username}
                      />
                      <span class="icon is-small is-left">
                        <figure className="image is-16x16">
                          <img
                            className="border is-rounded"
                            alt="Avatar"
                            src={
                              this.state.displayImage
                                ? this.state.displayImage
                                : "https://firebasestorage.googleapis.com/v0/b/algrof-f67fb.appspot.com/o/blank-prof.jpg?alt=media&token=a7e4511d-5f91-4010-8a20-0604ee3af6d2"
                            }
                          />
                        </figure>
                      </span>
                      <span class="icon is-small is-right">
                        <i class="fas fa-check"></i>
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </ul>
          </div>
        );
      });
    }

    var isgov;
    if (this.state.ivoted === true) {
      isgov = (
        <Tooltip
          description={"Period 1 Governor"}
          direction={"bottom"}
          source={
            "https://firebasestorage.googleapis.com/v0/b/algrof-f67fb.appspot.com/o/gov-hat-1.png?alt=media&token=165a5ed0-13c6-4b2e-bdaa-576087227742"
          }
        />
      );
    }

    var isgovPeriod2;
    if (this.state.ivotedPeriod2 === true) {
      isgovPeriod2 = (
        <Tooltip
          description={"Period 2 Governor"}
          direction={"bottom"}
          source={
            "https://firebasestorage.googleapis.com/v0/b/algrof-f67fb.appspot.com/o/period2-gov-badge.png?alt=media&token=52082853-4502-495a-ad84-cea2fa427d9e"
          }
        />
      );
    }

    var isAlphaTester;
    if (this.state.users === true) {
      isAlphaTester = (
        <Tooltip
          description={"⛷️algo.ski pre-alpha tester"}
          direction={"bottom"}
          source={
            "https://firebasestorage.googleapis.com/v0/b/algrof-f67fb.appspot.com/o/ski-patrol.png?alt=media&token=3af8b8d1-2952-46c3-aabe-3b191f3be02d"
          }
        />
      );
    }

    var isVerifiedUser;
    if (this.state.isVerified === "yes") {
      isVerifiedUser = (
        <Tooltip
          description={"Definitely not a bot"}
          direction={"bottom"}
          source={
            "https://firebasestorage.googleapis.com/v0/b/algrof-f67fb.appspot.com/o/genuine-badge.png?alt=media&token=75937364-4d35-4f9c-8896-53e278d0748e"
          }
        />
      );
    }

    var isgovn;
    if (this.state.ivoted === true) {
      isgovn = (
        <p>
          <span className="tag" style={{ marginBottom: "5px" }}>
            Governance
          </span>{" "}
          <a
            href={
              "https://governance.algorand.foundation/governance-period-1/governors/" +
              this.state.algoAddress
            }
            style={{ color: "#D3D3D3" }}
          >
            <u>View Period #1</u>
            <img
              style={{
                width: "10px",
                verticalAlign: "baseline",
                marginLeft: "5px",
              }}
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/OOjs_UI_icon_external-link-ltr-invert.svg/2048px-OOjs_UI_icon_external-link-ltr-invert.svg.png"
            />
          </a>
        </p>
      );
    } else if (this.state.ivoted === false) {
      isgovn = <p></p>;
    }

    var isgovnPeriod2;
    if (this.state.ivotedPeriod2 === true) {
      isgovnPeriod2 = (
        <p>
          <span className="tag" style={{ marginBottom: "5px" }}>
            Governance
          </span>{" "}
          <a
            href={
              "https://governance.algorand.foundation/governance-period-2/governors/" +
              this.state.algoAddress
            }
            style={{ color: "#D3D3D3" }}
          >
            <u>View Period #2</u>
            <img
              style={{
                width: "10px",
                verticalAlign: "baseline",
                marginLeft: "5px",
              }}
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/OOjs_UI_icon_external-link-ltr-invert.svg/2048px-OOjs_UI_icon_external-link-ltr-invert.svg.png"
            />
          </a>
        </p>
      );
    } else if (this.state.ivoted === false) {
      isgovnPeriod2 = <p></p>;
    }

    return (
      <div>
        <div style={this.state.blur ? {filter:"blur(10px)"} : {}}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {this.state.username +
              (this.state.isVerified ? " | Verified User" : "") +
              " | algo.ski" || this.state.algoAddress}
          </title>
          <meta
            name="description"
            content={
              (this.state.username || "Anonymous User") +
                "'s algo.ski profile | " +
                this.state.bio || "This user has not provided a bio"
            }
          />
          <link
            rel="canonical"
            href={"https://algo.ski/u/" + this.state.algoAddress}
          />
          <meta
            name="twitter:title"
            content={
              this.state.username +
                (this.state.isVerified ? " | Verified User" : "") +
                " | algo.ski" || this.state.algoAddress
            }
          />
          <meta
            name="twitter:description"
            content={
              (this.state.username || "Anonymous User") +
                "'s algo.ski profile | " +
                this.state.bio || "This user has not provided a bio"
            }
          />
          <meta name="twitter:image" content={this.state.displayImage} />
          <meta name="twitter:card" content="summary_large_image" />


          <meta
            property="og:title"
            content={
              this.state.username +
                (this.state.isVerified ? " | Verified User" : "") +
                " | algo.ski" || this.state.algoAddress
            }
          />
          <meta
            property="og:url"
            content={"https://algo.ski/u/" + this.state.algoAddress}
          />
          <meta
            property="og:image"
            content={
              this.state.coverImage ||
              "https://9cover.com/images/ccovers/1362683988ski-in-the-air-abstract.jpg}"
            }
          />
        </Helmet>
        <div
          className="hero"
          style={{
            backgroundImage:
              cover ??
              `url("https://i.icanvas.com/list-hero/sports-skiing.jpg")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
          }}
        >
          <div className="hero-body cover-height"></div>
        </div>
        <div className="minh container mb-6">
          <div className="columns dark-primary">
            <div
                className={this.state.staff === 'yes' ? "column outer-circle is-one-third extra-margin": "column box is-one-third extra-margin"}
                style={this.state.staff !== 'yes' ? { backgroundColor: "#1a1a1a"}: {}}
                >
                  {this.state.staff === 'yes' ? <p className="has-text-centered has-text-weight-semibold is-size-7" style={{marginTop:"-10px"}}>ALGO.SKI STAFF</p> : ""}
              <div className={this.state.staff === 'yes' ? "box" : ""}
              style={this.state.staff === 'yes' ? { backgroundColor: "#1a1a1a" }: {}}
              >
                <div className={`modal ${activeVerify}`}>
                  <div class="modal-background"></div>
                  <div class="modal-card">
                    <div class="">
                      <header
                        style={{ backgroundColor: "#414141" }}
                        className="modal-card-head"
                      >
                        <p className="has-text-white modal-card-title">
                          Verify your account
                        </p>
                        <button
                          onClick={this.handleClickVerify}
                          className="delete"
                          aria-label="close"
                        />
                      </header>
                      <div
                        style={{ backgroundColor: "#191919" }}
                        className="modal-card-body"
                      >
                        <div className="field">
                          <label className="label has-text-white">
                            Your phone number
                          </label>

                          <div class="field">
                            <p
                              class="control"
                              style={{
                                maxWidth: "300px",
                                backgroundColor: "#414141",
                              }}
                            >
                              <PhoneInput
                                style={{
                                  maxWidth: "300px",
                                  backgroundColor: "#414141",
                                }}
                                placeholder="Enter phone number"
                                initialValueFormat="national"
                                className="input"
                                labels={us}
                                defaultCountry="US"
                                value={this.state.phone}
                                onChange={(phone) => this.setState({ phone })}
                              />
                            </p>
                            <p className="control mt-2">
                              {this.state.loading === true ? (
                                <span className="button is-primary has-text-black is-loading">
                                  Send verification
                                </span>
                              ) : (
                                <span
                                  className="button is-primary has-text-black"
                                  onClick={this.verifySMS}
                                >
                                  Send verification
                                </span>
                              )}
                            </p>
                          </div>
                          {/* <div className="control">
                    <input
                      className="input"
                      type="text"
                      onChange={this.handleVerifyInput}
                      placeholder="Enter your mobile number"
                    />
                  </div> */}
                        </div>
                        {/* {isVerified} */}
                        <br />
                        {this.state.loading ? (
                          <InputCode
                            length={5}
                            label="Enter the 5 digit code sent to your device"
                            onComplete={(code) => {
                              this.checkVerifySMS(code);
                            }}
                          />
                        ) : (
                          ""
                        )}
                        <span className="mt-4 mb-2 has-text-bold has-text-error">
                          {this.state.verifySuccess === true ? (
                            <span className="has-text-success mt-4">
                              Your account was successfully verified
                            </span>
                          ) : (
                            ""
                          )}{" "}
                          {this.state.verifyError ? (
                            <span className="has-text-danger mt-4">
                              There was an error. You should try again
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                        <h3 className="mt-5 label has-text-white mb-2">
                          Why verify?
                        </h3>
                        <p className="subtitle is-6 has-text-grey-light">
                          While verifying your account is optional, some
                          projects require verified accounts to earn assets. We
                          never directly store, save, or share your phone number
                          for <u>any</u> purposes other than to verify that you
                          have access to the serviced device.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={`modal ${activeProf}`}>
                  <div className="modal-background" />
                  <div className="modal-card">
                    <header className="modal-card-head">
                      <p className="modal-card-title">Edit your profile</p>
                      <button
                        onClick={this.handleClickProf}
                        className="delete"
                        aria-label="close"
                      />
                    </header>
                    <section className="modal-card-body">
                      <div className="field">
                        <label className="label">Username</label>
                        <div className="control">
                          <input
                            className="input has-text-white"
                            style={{ backgroundColor: "#444444" }}
                            type="text"
                            onChange={this.handleUsernameChange}
                            defaultValue={this.state.username}
                            placeholder="Choose a username"
                          />
                        </div>
                      </div>
                      <div class="field">
                        <label className="label">Avatar</label>
                        <div style={{ width: "100px" }}>
                          {this.state.isUploading && (
                            <p className="has-text-black">Uploading...</p>
                          )}
                          {this.state.displayImage && (
                            <img src={this.state.displayImage} />
                          )}
                        </div>
                        <label
                          class="button"
                          style={{
                            backgroundColor: "#ebebeb",
                            color: "black",
                            padding: 10,
                            borderRadius: 4,
                            cursor: "pointer",
                          }}
                        >
                          Upload avatar
                          <FileUploader
                            hidden
                            accept="image/*"
                            name="displayImage"
                            randomizeFilename
                            storageRef={firebaseApp.storage().ref("images")}
                            onUploadStart={this.handleUploadStart}
                            onUploadError={this.handleUploadError}
                            onUploadSuccess={this.handleUploadSuccess}
                            onProgress={this.handleProgress}
                          />
                        </label>
                      </div>

                      <div class="field">
                        <label className="label">Cover Photo</label>
                        <div style={{ width: "300px" }}>
                          {this.state.isUploading && (
                            <p className="has-text-black">Uploading...</p>
                          )}
                          {this.state.coverImage && (
                            <img src={this.state.coverImage} />
                          )}
                        </div>
                        <label
                          class="button"
                          style={{
                            backgroundColor: "#ebebeb",
                            color: "black",
                            padding: 10,
                            borderRadius: 4,
                            cursor: "pointer",
                          }}
                        >
                          Upload cover photo
                          <FileUploader
                            hidden
                            accept="image/*"
                            name="coverImage"
                            randomizeFilename
                            storageRef={firebaseApp.storage().ref("images")}
                            onUploadStart={this.handleUploadStartCover}
                            onUploadError={this.handleUploadErrorCover}
                            onUploadSuccess={this.handleUploadSuccessCover}
                            onProgress={this.handleProgressCover}
                          />
                        </label>
                      </div>
                      <div class="field">
                        <label className="label">Bio</label>
                        <div className="control">
                          <textarea
                            className="textarea colorit"
                            style={{
                              backgroundColor: "#444444",
                              color: "white",
                            }}
                            placeholder="Create a bio"
                            defaultValue={this.state.bio}
                            onChange={this.handleBioChange}
                          />
                        </div>
                      </div>
                      {/* <div className="field">
                  <label className="label">Links</label>
                  <LinkForm addLinkToState={this.addLinkToState} />
        {hasLinks}
                </div> */}
                    </section>
                    <footer className="modal-card-foot">
                      <button
                        onClick={this.handleClickProf}
                        className="button is-dark"
                      >
                        Cancel
                      </button>
                      <button
                        className="button is-primary"
                        onClick={this.updateDB}
                      >
                        Save changes
                      </button>
                    </footer>
                  </div>
                </div>
                <div
                  className="p-5"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div>
                    <figure className="image is-96x96 is-square">
                      <img
                        className="border"
                        alt="Avatar"
                        src={
                          this.state.displayImage
                            ? this.state.displayImage
                            : "https://firebasestorage.googleapis.com/v0/b/algrof-f67fb.appspot.com/o/blank-prof.jpg?alt=media&token=a7e4511d-5f91-4010-8a20-0604ee3af6d2"
                        }
                      />
                    </figure>
                  </div>
                  <div style={{ marginLeft: "20px" }}>
                    <span className="">
                      <span className="media-title">
                        <span
                          className="title has-text-white is-spaced is-size-3"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {!this.state.username ? (
                            "Anonymous"
                          ) : (
                            <span>{this.state.username}</span>
                          )}
                        </span>

                        {/* <h2 className="subtitle">{this.state.algoAddress}</h2> */}
                        {/* <Task title={this.state.username} /> */}
                        {/* <p className="title is-3 has-text-weight-bold">
                  {this.state.username}
                </p> */}
                      </span>
                      {isConnected}
                      <span className="subtitle is-6">
                        {this.state.isVerified === "yes"
                          ? verifiedSymbol
                          : verifyIt}
                      </span>
                    </span>
                    {/* <p className="button is-small is-dark">
                {this.state.myAddress ? (
                  <p>
                    <a onClick={this.handleConnect}> {this.state.myAddress} </a>
                  </p>
                ) : null}{" "}
              </p> */}
                  </div>
                </div>
                <section className="ml-4">
                  <div className="container mr-4">
                    {/* <h3 className="subtitle is-6">Wallet: {this.state.myAddress}</h3> */}

                    <div className="column level">
                      {this.state.algoAddress ===
                      localStorage.getItem("myAddress") ? (
                        <div className="">
                          <span className=""></span>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="level-left">
                        <h1
                          className="title is-4 level-item"
                          style={{ display: "inline-flex" }}
                        >
                          {this.state.algoAddress ===
                          localStorage.getItem("myAddress") ? (
                            <div className="">
                              <span className="container"></span>
                            </div>
                          ) : (
                            ""
                          )}
                          {/* <span style={{ color: "#fff" }}>Profile</span>
                      {isOwnedWallet} */}
                          {/* <span
                    className="button is-small is-text level-item"
                    onClick={this.handleClick}
                  >
                    {" "}
                    edit profile
                  </span> */}
                        </h1>
                      </div>
                      <p className="subtitle is-5 is-size-6-mobile has-text-weight-semibold">
                        <span style={{ color: "#fff" }}>Badges</span>
                      </p>
                      <div>
                        {/* <img
                  className="inline is-rounded"
                  src="https://s2.coinmarketcap.com/static/img/coins/200x200/4030.png"
                  alt="badge"
                  style={{ width: "50px", marginRight: "8px" }}
                /> */}
                        {!isVerifiedUser && !isgov && !isAlphaTester ? (
                          <p
                            className="has-text-white"
                            style={{ marginTop: "-10px" }}
                          >
                            Nothing to see here
                          </p>
                        ) : (
                          ""
                        )}
                        {isVerifiedUser}
                        {isgov}
                        {isAlphaTester}
                        {isgovPeriod2}

                        {/* <img
                  className="inline is-rounded"
                  src="https://i.ibb.co/dm2Q8zp/governor-badge.png"
                  alt="badge"
                  style={{ width: "50px", marginRight: "8px" }}
                /> */}
                      </div>
                      <br />
                      <p className="subtitle is-5 is-size-6-mobile has-text-weight-semibold">
                        <span style={{ color: "#fff" }}>Bio</span>
                      </p>
                      <div style={{ marginTop: "-15px" }}>
                        <p className="is-6" style={{ color: "#ebebeb" }}>
                          {this.state.bio ? this.state.bio : "No bio provided"}
                        </p>
                      </div>
                      <br />
                      <p className="subtitle is-5 is-size-6-mobile has-text-weight-semibold">
                        <span style={{ color: "#fff" }}>NFTs</span>
                        <p className="mt-2" style={{ color: "#ebebeb" }}>
                          <a
                            onClick={this.handleClickGallery}
                            className="is-size-6 has-text-primary"
                          >
                            View NFTs
                          </a>
                        </p>
                      </p>

                      <div style={{ marginTop: "-15px" }}>
                        <p style={{ color: "#ebebeb" }}></p>
                      </div>

                      {/* <h2>{this.state.net}</h2> */}
                      <br />
                      {!this.state.linksList && !this.state.ivoted ? (
                        ""
                      ) : (
                        <p className="subtitle is-5 is-size-6-mobile has-text-weight-semibold">
                          <span style={{ color: "#fff" }}>Links</span>
                        </p>
                      )}
                      <div style={{ marginTop: "-15px" }}>
                        {this.state.linksList &&
                        !this.state.ivoted &&
                        !this.state.ivotedPeriod2 ? (
                          <p className="has-text-white">
                            There aren't any links
                          </p>
                        ) : (
                          ""
                        )}
                        {hasLinksDisplay}
                        {isgovn}
                        {isgovnPeriod2}
                      </div>
                      <br />
                      {/* <button className="button is-danger is-rounded is-text is-outlined is-small"><strong>Report suspicious activity</strong></button> */}

                      <br />
                      {/* <button className="button" onClick={this.handleConnect}>
                  Click to Connect
                </button>
                <br></br>
                <br></br>
                <button className="button" onClick={this.handleSend}>
                  Click to Send Transaction
                </button> */}
                      {/* <br></br>
              <button onClick={this.fetchBalance}>
                Click to check balance
              </button> */}
                      <br></br>
                      {/* <p>{"Connected Address: " + this.state.myAddress}</p> */}
                      <br></br>
                      {/* <p className="title is-6">
                  {"Balance: " + this.state.balance}{" "}
                  <span className="subtitle is-6">
                    <a href="#" className="link" onClick={this.fetchBalance}>
                      check balance
                    </a>
                  </span>
                </p> */}
                      {/* <p className="subtitle is-6">
                  {"Transaction ID: " + this.state.txID}
                </p> */}
                    </div>
                    <div>
                      {/* <h1 className="title is-4">NFT Gallery</h1> */}
                      {/* <Gal address={this.state.algoAddress} /> */}
                      {/* <NftGal address={this.state.algoAddress}/> */}

                      {/* <Gallery address={this.state.algoAddress} /> */}
                      {/* <select className="select" onChange={this.colorPicker}>
                <option value="red">red</option>
                <option value="blue">bloo</option>
              </select>
   
              <p className="control">
                <button onClick={this.updateDB} className="button">
                  Update
                </button>
              </p> */}
                    </div>
                    <div className={`modal ${active}`}>
                      <div class="modal-background"></div>
                      <div class="modal-card">
                        <div class="">
                          <header className="modal-card-head">
                            <p className="modal-card-title">
                              Connect your wallet
                            </p>
                            <button
                              onClick={this.handleClick}
                              className="delete"
                              aria-label="close"
                            />
                          </header>
                          <div className="modal-card-body">
                            <div className="buttons">
                              <button
                                class="button is-large"
                                onClick={this.handleConnectOfficial}
                              >
                                <span class="icon is-medium">
                                  <img src="https://dl.airtable.com/.attachmentThumbnails/263ef9f20a2f2773a0d35ad2be9b5ade/b80ce5fe" />
                                </span>
                                <span>Algorand Wallet</span>
                              </button>
                              <button
                                class="button is-large"
                                onClick={this.handleConnect}
                              >
                                <span class="icon is-medium">
                                  <img src="https://ml.globenewswire.com/Resource/Download/abe9938b-0ff0-4d09-8f8e-da43ca142899?size=2" />
                                </span>
                                <span>myAlgo Wallet</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>

            <div className={`modal ${activeGallery}`}>
              <div class="modal-background"></div>
              <div
                class="modal-card mobile-100-percent"
                style={{ width: "80%" }}
              >
                <div class="">
                  <header
                    style={{ backgroundColor: "#414141" }}
                    className="modal-card-head"
                  >
                    <p className="has-text-white is-size-6-mobile modal-card-title">
                      {this.state.username || "Anonymous"}'s NFT Collection
                    </p>
                    <button
                      onClick={this.handleClickGallery}
                      className="delete"
                      aria-label="close"
                    />
                  </header>
                  <div
                    style={{ backgroundColor: "#191919" }}
                    className="modal-card-body"
                  >
                    <Gallery redo={"yes"} address={this.state.algoAddress} />
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="column container">
        <div className="">
        <h1 className="title">Make a post</h1>
      <MDEdit/>
      </div>
      </div> */}
            {/* <div className="column is-hidden-mobile container ml-2 mt-4 mr-6">

      {this.getOwnPropertyNames(this.state.posts).map((post, i) => (
          <ul>
              <div className="card mb-4">      
              <div className="card-content">
                <p className="subtitle">{post}</p>
                 <MDEditor.Markdown source={post.value} />
               </div>
             </div></ul>
      ))}
        <Gallery address={this.state.algoAddress}/>
        <MDEdit address={this.state.algoAddress}/>
        <h1 className="title is-5 pt-4">Updates</h1>
        {hasPosts}
      </div> */}
            {/* <div className="column is-one-fifth">
        
      </div> */}
            <div className="column">
              {/* <div className="level">
            <div className="level-left">

            </div>
            <div className="level-right">
              <button className="button is-danger is-rounded is-outlined is-small"><strong>Report suspicious activity</strong></button>
            </div>
          </div> */}
              {!localStorage.getItem("myAddress") ? (
                <div className="mt-5 pr-4 pl-4 add-padding-non-mobile">
                  <span
                    className="ml-2 mt-2 button is-primary level-item has-text-black mr-2"
                    onClick={this.handleClick}
                  >
                    {" "}
                    <b>Connect your wallet to claim or edit</b>
                  </span>
                  <div style={{ filter: "blur(10px)" }}>
                    <div className="pr-2 pl-2 add-padding-non-mobile">
                      <h1
                        className="is-size-4 level-item"
                        style={{ display: "inline-flex" }}
                      ></h1>

                      <div className="">
                        <section className="section">
                          <div className="field">
                            <label className="label has-text-grey-light">
                              Username
                            </label>
                            <div className="control">
                              <input
                                disabled
                                className="input has-text-white"
                                style={{ backgroundColor: "#444444" }}
                                type="text"
                                defaultValue={this.state.username}
                                placeholder="Choose a username"
                              />
                            </div>
                          </div>
                          <div className="columns">
                            <div className="column is-3">
                              <div className="mt-2 field">
                                <label className="label has-text-grey-light">
                                  Avatar
                                </label>
                                <div>
                                  {this.state.isUploading && (
                                    <p className="has-text-white">
                                      Uploading...
                                    </p>
                                  )}
                                  {this.state.displayImage && (
                                    <img
                                      style={{ height: "130px" }}
                                      src={this.state.displayImage}
                                    />
                                  )}
                                </div>
                                <button disabled class="button is-dark">
                                  Upload avatar
                                  <FileUploader
                                    hidden
                                    accept="image/*"
                                    name="displayImage"
                                    randomizeFilename
                                    storageRef={firebaseApp
                                      .storage()
                                      .ref("images")}
                                    onUploadStart={this.handleUploadStart}
                                    onUploadError={this.handleUploadError}
                                    onUploadSuccess={this.handleUploadSuccess}
                                    onProgress={this.handleProgress}
                                  />
                                </button>
                              </div>
                            </div>
                            <div className="column">
                              <div class="mt-2 field">
                                <label className="label has-text-grey-light">
                                  Cover Photo
                                </label>
                                <div>
                                  {this.state.isUploading && (
                                    <p className="has-text-white">
                                      Uploading...
                                    </p>
                                  )}
                                  {this.state.coverImage && (
                                    <img
                                      style={{ height: "130px" }}
                                      src={this.state.coverImage}
                                    />
                                  )}
                                </div>
                                <button disabled class="button is-dark">
                                  Upload cover photo
                                  <FileUploader
                                    hidden
                                    accept="image/*"
                                    name="coverImage"
                                    randomizeFilename
                                    storageRef={firebaseApp
                                      .storage()
                                      .ref("images")}
                                    onUploadStart={this.handleUploadStartCover}
                                    onUploadError={this.handleUploadErrorCover}
                                    onUploadSuccess={
                                      this.handleUploadSuccessCover
                                    }
                                    onProgress={this.handleProgressCover}
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="field">
                            <label className="label has-text-grey-light">
                              Bio
                            </label>
                            <div className="control">
                              <textarea
                                disabled
                                style={{ backgroundColor: "#444444" }}
                                className="textarea has-text-white"
                                placeholder="Create a bio"
                                defaultValue={this.state.bio}
                                onChange={this.handleBioChange}
                              />
                            </div>
                          </div>
                          {/* <div className="field">
                  <label className="label">Links</label>
                  <LinkForm addLinkToState={this.addLinkToState} />
        {hasLinks}
                </div> */}
                        </section>
                        <div className="buttons mt-4">
                          <button disabled className="button is-primary">
                            Save changes
                          </button>
                        </div>
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              ) : this.state.algoAddress !==
                localStorage.getItem("myAddress") ? (
                <div className="mt-5 pr-4 pl-4 add-padding-non-mobile">
                  <span
                    className="ml-2 mt-2 button is-primary level-item has-text-black mr-2"
                    disabled
                  >
                    {" "}
                    <b>Is this also your wallet?</b>
                  </span>
                  <p className="mt-2 has-text-centered has-text-warning">
                    Support for multiple wallets is not yet available.
                  </p>
                </div>
              ) : (
                <div className="pr-2 pl-2 add-padding-non-mobile">
                  <h1
                    className="is-size-4 level-item"
                    style={{ display: "inline-flex" }}
                  >
                    {isOwnedWallet}
                  </h1>
                  <div className="section desktoppy">
                    <div className="level">
                      <div className="level-left">
                        <h1 className="mb-2 has-text-white is-size-5">
                          Edit your profile
                        </h1>
                      </div>
                      <div className="level-right">{connected}</div>
                    </div>
                    <div className="field">
                      <label className="label has-text-grey-light">
                        Username
                      </label>
                      <div className="control">
                        <input
                          className="input has-text-white"
                          style={{
                            backgroundColor: "#444444",
                            maxWidth: "400px",
                          }}
                          type="text"
                          onChange={this.handleUsernameChange}
                          defaultValue={this.state.username}
                          placeholder="Choose a username"
                        />
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column is-3">
                        <div className="mt-2 field">
                          <label className="label has-text-grey-light">
                            Avatar
                          </label>
                          <div>
                            {this.state.isUploading && (
                              <p className="has-text-white">Uploading...</p>
                            )}
                            {this.state.displayImage && (
                              <img
                                style={{ height: "130px" }}
                                src={this.state.displayImage}
                              />
                            )}
                          </div>
                          <label class="button is-dark">
                            Upload avatar
                            <FileUploader
                              hidden
                              accept="image/*"
                              name="displayImage"
                              randomizeFilename
                              storageRef={firebaseApp.storage().ref("images")}
                              onUploadStart={this.handleUploadStart}
                              onUploadError={this.handleUploadError}
                              onUploadSuccess={this.handleUploadSuccess}
                              onProgress={this.handleProgress}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="column">
                        <div class="mt-2 field">
                          <label className="label has-text-grey-light">
                            Cover Photo
                          </label>
                          <div>
                            {this.state.isUploading && (
                              <p className="has-text-white">Uploading...</p>
                            )}
                            {this.state.coverImage && (
                              <img
                                style={{ height: "130px" }}
                                src={this.state.coverImage}
                              />
                            )}
                          </div>
                          <label class="button is-dark">
                            Upload cover photo
                            <FileUploader
                              hidden
                              accept="image/*"
                              name="coverImage"
                              randomizeFilename
                              storageRef={firebaseApp.storage().ref("images")}
                              onUploadStart={this.handleUploadStartCover}
                              onUploadError={this.handleUploadErrorCover}
                              onUploadSuccess={this.handleUploadSuccessCover}
                              onProgress={this.handleProgressCover}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="field">
                      <label className="label has-text-grey-light">Bio</label>
                      <div className="control">
                        <textarea
                          style={{
                            backgroundColor: "#444444",
                            maxWidth: "600px",
                          }}
                          className="textarea has-text-white"
                          placeholder="Create a bio"
                          defaultValue={this.state.bio}
                          onChange={this.handleBioChange}
                        />
                      </div>
                    </div>
                    {/* <div className="field">
                  <label className="label">Links</label>
                  <LinkForm addLinkToState={this.addLinkToState} />
        {hasLinks}
                </div> */}
                    <div className="buttons mt-4">
                      <button
                        className="button has-text-black is-primary"
                        onClick={this.updateDB}
                      >
                        Save changes
                      </button>
                    </div>
                    <br />
                    <br />
                  </div>
                  {/* <h2 className="title is-4" style={{ color: "#D3D3D3" }}>
              NFT Gallery
            </h2>
            <Gallery address={this.state.algoAddress} /> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div></div>
    );
  }
}

export default (props) => <Profile {...props} params={useParams()} />;
