import React from "react";
import { Helmet } from "react-helmet-async";
import Masonry from "react-masonry-css";
import "../main.css";

export default class Algoski extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <meta charset="utf-8" />
          <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="theme-color" content="#000000" />
          <meta name="description" content="algo.ski" />
          <link
            rel="apple-touch-icon"
            href="%PUBLIC_URL%/apple-touch-icon.png"
          />

          <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />

          <title>algo.ski | Roadmap</title>
        </Helmet>
        <div className="columns">
          {" "}
          <div className="column is-narrow">
              <div className="section container">
                  <div className="column">
                      <div className="box" style={{backgroundColor:"transparent",border:"1px solid white"}}>
                          <h1 className="title has-text-white">Spaces</h1>
                          <button className="button is-rounded">Create a space</button>
                          
                      </div>
                  </div>

</div>
              </div>
          <div className="column">
            <div className="section container">
                <div className="level mb-6">
            <input class="input is-rounded  is-white" style={{maxWidth:"400px", backgroundColor:"transparent"}}type="text" placeholder="Primary input"/>
            <div class="select is-rounded  is-white">
  <select class="has-text-grey-light "style={{backgroundColor:"transparent"}}>
    <option>Select dropdown</option>
    <option>With options</option>
  </select>
</div></div>
                <div className="is-flex is-flex-wrap-wrap has-text-centered">
                          {[1, 2, 3, 4, 5, 6, 7].map((slope) => 
                   <div className="box mr-5 mb-5" style={{backgroundColor:"transparent",border:"1px solid white"}}>
                  <div className="m-5">
                   <figure class="image is-64x64 is-inline-block"> 
                      <img src="https://firebasestorage.googleapis.com/v0/b/algrof-f67fb.appspot.com/o/ski-logo-print.png?alt=media&token=bdf510d9-a101-49f7-92bd-f57fa30e1936"/>
                     </figure>
                    
                      <h1 className="title has-text-white">algo.ski</h1>
                      <h1 className="subtitle has-text-white">2,342 members</h1>
 </div>
                </div>)}
            
                </div>
              
            </div>
          </div>
        </div>
      </div>
    );
  }
}
