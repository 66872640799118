import React from "react";

export const LinkListDisplay = (props) => {
  const emptyList = (length) => {
    if (length === 0) {
      return (
        <tr style={{ "text-align": "center" }}>
          <td colSpan="3"></td>
        </tr>
      );
    }
  };

  const deleteActionItemFromState = (index) => () => {
    props.deleteActionItemFromState(index);
  };

  return (
    <div className="">
      <table className="">
        <tbody>
          {emptyList(props.actionItemsList.length)}
          {props.actionItemsList.map((linkSite, i) => (
            <tr key={i + 1}>
              <a
                className="button is-text is-small is-dark"
                target="_blank"
                href={linkSite.linkUrl}
              >
                 <img
                          style={{
                            width: "12px",
                            verticalAlign: "baseline",
                            marginRight: "9px",
                          }}
                          src={props.icon}
                        />{linkSite.linkSite}
                <img
                  style={{
                    width: "10px",
                    verticalAlign: "baseline",
                    marginLeft: "5px",
                  }}
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/OOjs_UI_icon_external-link-ltr-invert.svg/2048px-OOjs_UI_icon_external-link-ltr-invert.svg.png"
                />
              </a>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
