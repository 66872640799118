import React, { Component, useEffect, useState } from "react";
import firebaseApp from "../firebase";
import axios from "axios";
import Gallery from "../components/Gallery.tsx";
import Reaction from "../components/Reaction.tsx";
import InputCode from "../components/InputCode";
import "../components/phone.css";
import PhoneInput from "react-phone-number-input";
import { Helmet } from "react-helmet-async";
import us from "react-phone-number-input/";
import { useParams } from "react-router-dom";
import Pipeline from "@pipeline-ui-2/pipeline"; //change to import Pipeline from 'Pipeline for realtime editing Pipeline index.js, and dependency to: "Pipeline": "file:..",
import "bulma/css/bulma.min.css";
import "../main.css";
import {
  getAssetsOfAddress,
  getCollectionFromTokens,
  getDetailsOfAsset,
  getDetailsOfAssets,
} from "../lib/algohelpers.ts";
import { NFT } from "../lib/nft.ts";
import Tooltip from "../components/Tooltip";
import FileUploader from "react-firebase-file-uploader";
import { LinkForm, LinkList } from "../components/LinkForm";
import { LinkListDisplay } from "../components/LinkListDisplay";
import MDEdit from "../components/MDEdit";
import MDEditor from "@uiw/react-md-editor";

// const accountSid = process.env.TWILIO_ACCOUNT_SID;
// const authToken = process.env.TWILIO_AUTH_TOKEN;
// const client = require('twilio')(accountSid, authToken);

//change recipient address before sending transaction
const recipient = "KCZDQ2Y3OBDBW6NYGYDTOQ6GPW345H3EM2KYI3DPF54GZL4SKWNOQH2FHM";
const cryptrSecret = process.env.REACT_APP_CRYPTR_SECRET;
const myAlgoWallet = Pipeline.init();
const WalletConnect = Pipeline.init();

const Cryptr = require('cryptr');
const cryptr = new Cryptr(`${cryptrSecret}`);

const resExample = `{
"isVerified":true,
"verifyMethod":"sms"
}`


//amount in micro Algos
const amount = 0;

const note = "red";

//0 = Algorand, otherwise index number of asset
const index = 0;

//set to false to switch to TestNet
Pipeline.main = true;

var mynet = Pipeline.main ? "MainNet" : "TestNet";

class Verify extends React.Component {
  state = {
    myAddress: "Connect your wallet",
    color: "none",
    balance: "n/a",
    username: "",
    phone: "",
    smsNum: "",
    displayImage: "",
    progress: 0,
    isUploading: false,
    verifyError: false,
    verifyResponse: "",
    isVerified: "",
    verifiedSMS: "",
    coverImage: "",
    users: "",
    bio: "",
    isModal: false,
    isModalProf: false,
    isModalVerify: false,
    verifyInput: "",
    algoAddress: this.props.params.username,
    ivoted: false,
    linksList: [],
    quizActive: true,
    loading: false,
    setLoading: false,
    currentQuiz: 0,
    currentQuestion: 0,
    currentAnswers: [],
    attemptedQuestions: 0,
    score: 0,
    quizzes: [],
    postChild: false,
    posts: [
      {
        post: {
          content: "",
          timestamp: "",
        },
      },
    ],
  };

  verifySMS = () => {
    var axios = require("axios");

    var config = {
      method: "post",
      url:
        "https://verify-sample-5247-dev.twil.io/start-verify?to=%2B" +
        this.state.phone +
        "&locale=en&channel=sms",
      headers: {
        "Content-Type": "application/json",
        Authorization:
        "Basic " + process.env.REACT_APP_TWILIO_AUTH_TOKEN,
            },
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
    this.setState({ loading: true });
  };

  checkVerifySMS = (code) => {
    var axios = require("axios");

    var config = {
      method: "post",
      url:
        "https://verify-sample-5247-dev.twil.io/check-verify?to=%2B" +
        this.state.phone +
        "&verification_code=" +
        code,
      headers: {
        "Content-Type": "application/json",
        Authorization:
        "Basic " + process.env.REACT_APP_TWILIO_AUTH_TOKEN,
            },
    };

    axios(config)
      .then((response) => {
        console.log(response);
        const res = response.data;
        const verifiedStatus = res.success;
        this.setState({ verifySuccess: verifiedStatus });
        if (verifiedStatus) {
          this.updateVerify();
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ verifyError: true });
        setTimeout(() => this.setState({ verifyError: false }), 4000);
      });
    // .then(function (response) {
    //   console.log(JSON.stringify(response.data));
    //   if (response.data) {
    //   this.setState({verifyResponse: response.data})
    // }
    // })
    // .catch(function (error) {
    //   console.log(error);
    //   if (error) {
    //   this.setState({verifyError: true});
    //   setTimeout(() => this.setState({verifyError : false}), 2000);
    // }});
  };

  getGov = () => {
    var axios = require("axios");

    var config = {
      method: "GET",
      url:
        "https://governance.algorand.foundation/api/periods/governance-period-1/governors/" +
        this.state.algoAddress +
        "/activities/",
    };

    axios(config)
      .then((response) => {
        const govdata = response.data;
        const ivoted = govdata.count;
        if (ivoted > 0) {
          this.setState({ ivoted: true });
        }
      })
      .catch((error) => {
        console.log("Not a governor");
      });
  };

  handleVerifyInput = (event) =>
    this.setState({ verifyInput: event.target.value });

  addLinkToState = (linkSite, linkUrl) => {
    let toBeAddedActionItem = {
      linkSite: linkSite,
      linkUrl: linkUrl,
    };
    if (this.prevState) {
      this.setState((prevState) => ({
        linksList: prevState.linksList.concat(toBeAddedActionItem),
      }));
    }
  };
  deleteActionItemFromState = (index) => {
    const linksList = [...this.state.linksList];
    linksList.splice(index, 1);
    this.setState({ linksList });
  };

  handleChangeUsername = (event) =>
    this.setState({ username: event.target.value });
  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
  handleProgress = (progress) => this.setState({ progress });
  handleUploadError = (error) => {
    this.setState({ isUploading: false });
    console.error(error);
  };
  handleUploadSuccess = (filename) => {
    this.setState({ avatar: filename, progress: 100, isUploading: false });
    firebaseApp
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL()
      .then((url) => this.setState({ displayImage: url }));
  };

  handleUploadStartCover = () =>
    this.setState({ isUploading: true, progress: 0 });
  handleProgressCover = (progress) => this.setState({ progress });
  handleUploadErrorCover = (error) => {
    this.setState({ isUploading: false });
    console.error(error);
  };
  handleUploadSuccessCover = (filename) => {
    this.setState({ avatar: filename, progress: 100, isUploading: false });
    firebaseApp
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL()
      .then((url) => this.setState({ coverImage: url }));
  };

  dbRef = firebaseApp.database();
  updateDB = this.updateDB.bind(this);

  updateDB() {
    const myAddress = localStorage.getItem('myAddress');
    const username = this.state.username;
    const bio = this.state.bio;
    const coverImage =
      this.state.coverImage ??
      "https://9cover.com/images/ccovers/1362683988ski-in-the-air-abstract.jpg";
    const links = this.state.linksList ?? null;
    const displayImage =
      this.state.displayImage ??
      "https://firebasestorage.googleapis.com/v0/b/algrof-f67fb.appspot.com/o/blank-prof.jpg?alt=media&token=a7e4511d-5f91-4010-8a20-0604ee3af6d2";
    if (links) {
      this.dbRef
        .ref("/users/")
        .child(myAddress)
        .update({ myAddress, username, bio, coverImage, displayImage });
    } else {
      this.dbRef
        .ref("/users/")
        .child(myAddress)
        .update({ myAddress, username, bio, coverImage, displayImage, links });
    }
    this.setState({ isModalProf: !this.state.isModalProf });
  }

  updateAddy() {
    const myAddress = localStorage.getItem('myAddress');
    this.dbRef.ref("/users/").child(myAddress).update({ myAddress });
  }

  updateVerify() {
    const myAddress = localStorage.getItem('myAddress');
    this.dbRef.ref("/users/").child(myAddress).update({ isVerified: "yes", verifiedSMS: cryptr.encrypt(this.state.phone) });
    setTimeout(
      () => this.setState({ isModalVerify: !this.state.isModalVerify }),
      2000
    );
  }


  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     net: mynet,
  //     txID: "",
  //     myAddress: "",
  //     balance: 0,
  //     color: "color"
  //   };
  // }

  fetchBalance = () => {
    Pipeline.balance(this.state.myAddress).then((data) => {
      this.setState({ balance: data });
    });
  };

  handleConnect = () => {
    Pipeline.pipeConnector = "myAlgoWallet";
    Pipeline.connect(myAlgoWallet).then((data) => {
      this.setState({ myAddress: data });
      localStorage.setItem("myAddress", data);
      this.updateAddy();
      window.location.href = "/verify/";
    });
  };

  handleConnectOfficial = () => {
    Pipeline.pipeConnector = "WalletConnect";
    Pipeline.connect(WalletConnect).then((data) => {
      this.setState({ myAddress: data });
      localStorage.setItem("myAddress", data);
      this.updateAddy();
      window.location.href = "/verify/";
    });
  };

  generateRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  handleSend = () => {
    Pipeline.send(
      recipient,
      amount,
      note,
      this.state.myAddress,
      myAlgoWallet,
      index
    ).then((data) => {
      this.setState({ txID: data });
    });
  };

  toggleChild = () => {
    this.setState((prevState) => ({
      postChild: !prevState.postChild,
    }));
  };

  Child = ({ toggleChild }) => {
    return (
      <div>
        <input className="input is-rounded" />
        <div className="buttons mt-2">
          <button className="button mt-2 is-small is-primary">Post</button>
        </div>
      </div>
    );
  };

  switchConnector = (event) => {
    Pipeline.pipeConnector = event.target.value;
    console.log(Pipeline.pipeConnector);
  };

  colorPicker = (event) => {
    this.setState({ color: event.target.value });
    console.log(event.target.value);
  };

  getVerifyStatus = () => {
    let ref = firebaseApp.database().ref("/users/" + localStorage.getItem('myAddress'));
    ref.on("value", (snapshot) => {
      const users = snapshot.val();
      if (users) {
        this.setState({
          isVerified: users.isVerified,
        });
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isVerified !== this.state.isVerified) {
      this.getVerifyStatus();
    }
  }

  componentDidMount() {
    let ref = firebaseApp.database().ref("/users/" + localStorage.getItem('myAddress'));
    ref.on("value", (snapshot) => {
      const users = snapshot.val();
      if (users) {
        this.setState({
          users: true,
          bio: users.bio,
          username: users.username,
          displayImage: users.displayImage,
          coverImage: users.coverImage,
          linksList: users.links,
          posts: users.posts,
          isVerified: users.isVerified,
          verifiedSMS: users.verifiedSMS
        });
      }

      const { id } = this.props.params;
      this.getGov();
    });
  }

  clearStorage = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  handleClick = () => {
    this.setState({ isModal: !this.state.isModal });
  };

  handleClickProf = () => {
    this.setState({ isModalProf: !this.state.isModalProf });
  };

  handleChangePhone = (e) => this.setState({ phone: e.target.value });

  handleClickVerify = () => {
    this.setState({ isModalVerify: !this.state.isModalVerify });
    this.setState({ loading: false });
  };

  handleUsernameChange = (e) => this.setState({ username: e.target.value });
  handleBioChange = (e) => this.setState({ bio: e.target.value });

  render() {
    // const [uploadedImage, setUploadedImage] = useState(null);
    // const [imageSrc, setImageSrc] = useState("");

    // const handleUpload = async () => {
    //   const formData = new FormData();
    //   formData.append("file", uploadedImage);
    //   formData.append("upload_preset", "xuit9n89");

    //   const res = await axios.post("https://api.cloudinary.com/v1_1/dgg6qf2qy/image/upload", formData);
    //   // store image url in db.
    //   setImageSrc(res.data.url);
    // }

    // this.response.data.map(group => Object.keys(group.price).map(priceOpt => console.log(priceOpt, group.price[priceOpt])));

    const active = this.state.isModal ? "is-active" : "";
    const activeProf = this.state.isModalProf ? "is-active" : "";
    const activeVerify = this.state.isModalVerify ? "is-active" : "";

    var verifiedSymbol;
    if (this.state.isVerified === "yes") {
      verifiedSymbol = (
          <button className="button letterspacing is-static is-primary is-outlined has-text-primary mt-2 is-size-6-mobile mb-1 mr-4">
            <span>          <img style={{width:"16px",marginBottom:"-3px",marginRight:"3px"}} src="https://firebasestorage.googleapis.com/v0/b/algrof-f67fb.appspot.com/o/ski-logo-print.png?alt=media&token=bdf510d9-a101-49f7-92bd-f57fa30e1936"/>VERIFIED</span>
          </button>
      );
    } else if (
      this.state.isVerified !== "yes" &&
      this.state.algoAddress === localStorage.getItem("myAddress")
    ) {
      verifiedSymbol = (
        <span
          className="is-size-7 button has-text-black is-primary is-small"
          onClick={this.handleClickVerify}
        >
          Verify your account
        </span>
      );
    } else {
      verifiedSymbol = (
        <button className="is-loading button has-text-black is-primary mt-4 button title is-5 mb-1 mr-4">
          Loading
        </button>
      )}

    var connected;
    if (localStorage.getItem("myAddress") === null) {
      connected = (
        <button
          onClick={this.handleClick}
          style={{ minWidth: "300px" }}
          className="is-fullwidth button is-small is-primary has-text-black"
        >
          Connect your wallet
        </button>
      );
    } else if (localStorage.getItem("myAddress") === this.state.algoAddress) {
      connected = (
        <div>
          <div className="field has-addons">
            <span className="control">
              <button
                disabled
                className="button is-primary has-text-black is-small"
              >
                🟢 connected
              </button>
            </span>
            {/* <span className="control">
        {shouldVerify}</span> */}
            <span className="control">
              <p
                className="button is-clickable is-dark is-small"
                onClick={this.clearStorage}
              >
                sign out
              </p>
            </span>
          </div>
          <div>
            <p className="subtitle is-6">
              {this.state.isVerified !== "yes" ? verifiedSymbol : ""}
            </p>
          </div>
        </div>
      );
    }
    var isConnected;
    if (localStorage.getItem('myAddress')) {
      isConnected = (
        <p style={{overflow:"hidden"}}className="has-text-white subtitle mt-2 is-5 mb-1 has-text-weight-light">{localStorage.getItem('myAddress')}</p>
      );
    } else {
      isConnected = (
        <button className="button has-text-black is-primary mt-3" onClick={this.handleClick}>{this.state.myAddress}</button>
      );
    }

    var isOwnedWallet;
    if (this.state.myAddress === localStorage.getItem('myAddress')) {
      isOwnedWallet = (
        <span
          className="button is-small is-dark ml-2level-item"
          onClick={this.handleClickProf}
        >
          {" "}
          edit profile
        </span>
      );
    } else if (localStorage.getItem("myAddress") === this.state.algoAddress) {
      isOwnedWallet = (
        <span
          className="button is-small ml-2 is-dark level-item"
          onClick={this.handleClickProf}
        >
          {" "}
          edit profile
        </span>
      );
    } else if (localStorage.getItem("myAddress") === null) {
      isOwnedWallet = (
        <span
          className="ml-2 button is-small is-size-7 is-text is-dark level-item has-text-white"
          onClick={this.handleClick}
        >
          {" "}
          connect wallet to edit
        </span>
      );
    }

    var cover;
    if (this.state.coverImage) {
      cover = `url("${this.state.coverImage}")`;
    } else {
      cover = `url("https://picsum.photos/1000/400/?blur=5")`;
    }

 


   
    return (
      <div
        className="dark-primary minh container"
        style={{ backgroundColor: "#0e0d0d" }}
      >
        <Helmet>
        <meta charset="utf-8" />
    <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="theme-color" content="#000000" />
    <meta
      name="description"
      content="algo.ski VERIFY | Verify your wallet to earn ASAs"
    />
    <link rel="apple-touch-icon" href="%PUBLIC_URL%/apple-touch-icon.png" />

    <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
 
    <title>algo.ski | VERIFY </title>
 
        </Helmet>
        <div className="columns">
          <div className="column is-one-third-widescreen">
            <section
              className="box mt-6 mb-6" 
              style={{ backgroundColor: "transparent", border:"1px solid grey" }}
            >
                <div className={`modal ${activeVerify}`}>
                <div class="modal-background"></div>
                <div class="modal-card">
                  <div class="">
                    <header style={{backgroundColor:"#414141"}} className="modal-card-head">
                      <p className="has-text-white modal-card-title">Verify your account</p>
                      <button
                        onClick={this.handleClickVerify}
                        className="delete"
                        aria-label="close"
                      />
                    </header>
                    <div style={{backgroundColor:"#191919"}} className="modal-card-body">
                      <div className="field">
                        <label className="label has-text-white">Your phone number</label>

                        <div class="field">
                          <p class="control" style={{ maxWidth: "300px",backgroundColor:"#414141" }}>
                            <PhoneInput
                            style={{ maxWidth: "300px",backgroundColor:"#414141" }}
                              placeholder="Enter phone number"
                              initialValueFormat="national"
                              className="input"
                              labels={us}
                              defaultCountry="US"
                              value={this.state.phone}
                              onChange={(phone) => this.setState({ phone })}
                            />
                          </p>
                          <p className="control mt-2">
                            {this.state.loading === true ? (
                              <span className="button is-primary has-text-black is-loading">
                                Send verification
                              </span>
                            ) : (
                              <span
                                className="button is-primary has-text-black"
                                onClick={this.verifySMS}
                              >
                                Send verification
                              </span>
                            )}
                          </p>
                        </div>
                        {/* <div className="control">
                    <input
                      className="input"
                      type="text"
                      onChange={this.handleVerifyInput}
                      placeholder="Enter your mobile number"
                    />
                  </div> */}
                      </div>
                      {/* {isVerified} */}
                      <br />
                      {this.state.loading ? (
                        <InputCode
                          length={5}
                          label="Enter the 5 digit code sent to your device"
                          onComplete={(code) => {
                            this.checkVerifySMS(code);
                          }}
                        />
                      ) : (
                        ""
                      )}
                      <span className="mt-4 mb-2 has-text-bold has-text-error">
                        {this.state.verifySuccess === true ? (
                          <span className="has-text-success mt-4">
                            Your account was successfully verified
                          </span>
                        ) : (
                          ""
                        )}{" "}
                        {this.state.verifyError ? (
                          <span className="has-text-danger mt-4">
                            There was an error. You should try again
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                      <h3 className="mt-5 has-text-white label mb-2">Why verify?</h3>
                      <p className="subtitle is-6 has-text-grey-light">
                        While verifying your account is optional, some projects
                        require verified accounts to earn assets. We never
                        directly store, save, or share your phone number for{" "}
                        <u>any</u> purposes other than to verify that you have
                        access to the serviced device.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className={`modal ${active}`}>
                  <div class="modal-background"></div>
                  <div class="modal-card">
                    <div class="">
                      <header className="modal-card-head">
                        <p className="modal-card-title">Connect your wallet</p>
                        <button
                          onClick={this.handleClick}
                          className="delete"
                          aria-label="close"
                        />
                      </header>
                      <div className="modal-card-body">
                        <div className="buttons">
                          <button
                            class="button is-large"
                            onClick={this.handleConnectOfficial}
                          >
                            <span class="icon is-medium">
                              <img src="https://dl.airtable.com/.attachmentThumbnails/263ef9f20a2f2773a0d35ad2be9b5ade/b80ce5fe" />
                            </span>
                            <span>Algorand Wallet</span>
                          </button>
                          <button
                            class="button is-large"
                            onClick={this.handleConnect}
                          >
                            <span class="icon is-medium">
                              <img src="https://ml.globenewswire.com/Resource/Download/abe9938b-0ff0-4d09-8f8e-da43ca142899?size=2" />
                            </span>
                            <span>myAlgo Wallet</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              <div className={`modal ${activeProf}`}>
                <div className="modal-background" />
                <div className="modal-card">
                  <header className="modal-card-head">
                    <p className="modal-card-title">Edit your profile</p>
                    <button
                      onClick={this.handleClickProf}
                      className="delete"
                      aria-label="close"
                    />
                  </header>
                  <section className="modal-card-body">
                    <div className="field">
                      <label className="label">Username</label>
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          onChange={this.handleUsernameChange}
                          defaultValue={this.state.username}
                          placeholder="Choose a username"
                        />
                      </div>
                    </div>
                    <div class="field">
                      <label className="label">Avatar</label>
                      <div style={{ width: "100px" }}>
                        {this.state.isUploading && (
                          <p className="has-text-black">Uploading...</p>
                        )}
                        {this.state.displayImage && (
                          <img src={this.state.displayImage} />
                        )}
                      </div>
                      <label
                        class="button"
                        style={{
                          backgroundColor: "#ebebeb",
                          color: "black",
                          padding: 10,
                          borderRadius: 4,
                          cursor: "pointer",
                        }}
                      >
                        Upload avatar
                        <FileUploader
                          hidden
                          accept="image/*"
                          name="displayImage"
                          randomizeFilename
                          storageRef={firebaseApp.storage().ref("images")}
                          onUploadStart={this.handleUploadStart}
                          onUploadError={this.handleUploadError}
                          onUploadSuccess={this.handleUploadSuccess}
                          onProgress={this.handleProgress}
                        />
                      </label>
                    </div>

                    <div class="field">
                      <label className="label">Cover Photo</label>
                      <div style={{ width: "300px" }}>
                        {this.state.isUploading && (
                          <p className="has-text-black">Uploading...</p>
                        )}
                        {this.state.coverImage && (
                          <img src={this.state.coverImage} />
                        )}
                      </div>
                      <label
                        class="button"
                        style={{
                          backgroundColor: "#ebebeb",
                          color: "black",
                          padding: 10,
                          borderRadius: 4,
                          cursor: "pointer",
                        }}
                      >
                        Upload cover photo
                        <FileUploader
                          hidden
                          accept="image/*"
                          name="coverImage"
                          randomizeFilename
                          storageRef={firebaseApp.storage().ref("images")}
                          onUploadStart={this.handleUploadStartCover}
                          onUploadError={this.handleUploadErrorCover}
                          onUploadSuccess={this.handleUploadSuccessCover}
                          onProgress={this.handleProgressCover}
                        />
                      </label>
                    </div>
                    <div class="field">
                      <label className="label">Bio</label>
                      <div className="control">
                        <textarea
                          className="textarea"
                          placeholder="Create a bio"
                          defaultValue={this.state.bio}
                          onChange={this.handleBioChange}
                        />
                      </div>
                    </div>
                    {/* <div className="field">
                  <label className="label">Links</label>
                  <LinkForm addLinkToState={this.addLinkToState} />
        {hasLinks}
                </div> */}
                  </section>
                  <footer className="modal-card-foot">
                    <button
                      className="button is-warning"
                      onClick={this.updateDB}
                    >
                      Save changes
                    </button>
                    <button onClick={this.handleClickProf} className="button">
                      Cancel
                    </button>
                  </footer>
                </div>
              </div>
              <div
                className="section"
                style={{height:"100%"}}
              >
                <div className="is-inline-flex">
                  <img style={{width:"100px", marginBottom:"30px"}} alt="Algo.ski Verify Logo" src="https://firebasestorage.googleapis.com/v0/b/algrof-f67fb.appspot.com/o/verify-white.png?alt=media&token=c38e53b0-11ec-4fa9-b584-c82eba8bb83e"/>
                  <span style={{marginLeft:"6px"}}><Tooltip
          description={"Verify your account so projects can confirm that you are a genuine human. We never save, store or share your personal verification details"}
          direction={"bottom"}
          size={"20px"}
          tipsize={"180px"}
          source={
            "https://firebasestorage.googleapis.com/v0/b/algrof-f67fb.appspot.com/o/info%20(1).png?alt=media&token=850ca7ed-1f58-45a2-9530-0f657e33a04b"
          }
        /></span>
                </div>
                <div>
                  <span className="">
                    <h1 className="has-text-grey-light is-6">Wallet</h1>

                    {isConnected}
                  
                  </span>
                  {/* <p className="button is-small is-dark">
                {this.state.myAddress ? (
                  <p>
                    <a onClick={this.handleConnect}> {this.state.myAddress} </a>
                  </p>
                ) : null}{" "}
              </p> */}
                </div>
                <div className="mt-4">
                  <span>
                    <h1 className="has-text-grey-light is-6 mt-2">Verification</h1>
                    <h3 className="subtitle is-flex-direction-column">
                      {localStorage.getItem('myAddress') ? <div>{this.state.isVerified === "yes" ? verifiedSymbol : <button className="button has-text-black is-primary mt-2 mb-1 mr-4" onClick={this.handleClickVerify}>📱 Verify via SMS</button>}<div><button className="button has-text-black is-primary mt-4 mb-1" disabled>🔐 Secure via Smart Contract</button></div><p className="mt-2 is-size-7 has-text-warning">Currently in development</p></div> : <div><button className="button has-text-black is-primary mt-4 mb-1 mr-4" disabled>📱 Verify via SMS</button><button className="button has-text-black is-primary mt-4 mb-1" disabled>🔐 Secure via Smart Contract</button></div> }
                    </h3>
                  </span>
                  {/* <p className="button is-small is-dark">
                {this.state.myAddress ? (
                  <p>
                    <a onClick={this.handleConnect}> {this.state.myAddress} </a>
                  </p>
                ) : null}{" "}
              </p> */}
                </div>
                {!this.state.verifiedSMS ? "" : <div>
                 <p className="mt-2 is-size-7 has-text-grey-light">Verification hash</p>
          <p className="subtitle is-size-7">{this.state.verifiedSMS}</p></div>}
              </div>
            </section>
          
          </div>       <div className="column is-half">
              <div className="container mb-6 mobile-adjust">
                <div className="remove-ml section">
                  
                <h1 className={localStorage.getItem('myAddress') ? "title mt-6 is-5 has-text-white" : "title is-5 has-text-white" }>algo<span className="has-text-primary">.</span>ski Verify API</h1>
                <p className="is-7 subtitle has-text-warning">Full documentation in progress</p>
<p className="subtitle mt-3 is-6 has-text-grey-light">Utilize our public API endpoint to check any wallet's verification status.</p>

                <h1 className="is-5 has-text-white">Example HTTPS <code>GET</code> request</h1>
                  <p className="subtitle mt-3 is-6"><pre className="code">https://api.algo.ski/verify?address=ALGORAND_WALLET_ADDRESS</pre></p>
                  <br/>
                  <h1 className=" is-5 has-text-white">Example JSON response</h1>
                  <p className="subtitle mt-3 is-6"><pre className="code">{resExample} </pre></p>
                </div>
              </div>
        </div>
 
        </div>
        {/* <div className="column container">
        <div className="">
        <h1 className="title">Make a post</h1>
      <MDEdit/>
      </div>
      </div> */}
        {/* <div className="column is-hidden-mobile container ml-2 mt-4 mr-6">

      {this.getOwnPropertyNames(this.state.posts).map((post, i) => (
          <ul>
              <div className="card mb-4">      
              <div className="card-content">
                <p className="subtitle">{post}</p>
                 <MDEditor.Markdown source={post.value} />
               </div>
             </div></ul>
      ))}
        <Gallery address={this.state.algoAddress}/>
        <MDEdit address={this.state.algoAddress}/>
        <h1 className="title is-5 pt-4">Updates</h1>
        {hasPosts}
      </div> */}
        {/* <div className="column is-one-fifth">
        
      </div> */}
       
      </div>
    );
  }
}

export default (props) => <Verify {...props} params={useParams()} />;
