import React, { useEffect, useState } from "react";
import { Link, useParams, NavLink } from "react-router-dom";
import firebaseApp from "../firebase";
import ReactSearchBox from "react-search-box";
import Search from './Search'
import Pipeline from "@pipeline-ui-2/pipeline"; //change to import Pipeline from 'Pipeline for realtime editing Pipeline index.js, and dependency to: "Pipeline": "file:..",
import "../main.css";
import { set } from "firebase/database";

const reload = () => {
  window.location.reload(false);
};

const shouldReload = () => {
  const { id } = this.props.params;
  const addy = localStorage.getItem("myAddress");
  if (id.includes.addy(localStorage.getItem("myAddress"))) {
    reload();
  }
};

var connectedNav;
if (localStorage.getItem("myAddress") !== null) {
  connectedNav = (
    <Link
      onClick={shouldReload}
      className="is-dark hov has-text-white navbar-item is-outlined"
      style={{ marginLeft: "10px" }}
      to={"/u/" + localStorage.getItem("myAddress")}
    >
      👤{" "}
      <strong className="ml-2 letterspacing">
        {localStorage.getItem("myAddress").slice(0, 4)}..
        {localStorage.getItem("myAddress").slice(-4)}
      </strong>
    </Link>
  );
}

// } else {
//   connectedNav = (
//     <Link to="/" className="button is-primary">
//               <p><strong>Connect wallet</strong></p>
//             </Link>

//   )
// }



function Header(handleConnect) {

  return (
    <div>
      <nav
        className="navbar level overflow-auto"
        role="navigation"
        aria-label="main navigation"
        style={{ backgroundColor: "#222221", padding: "5px 0px" }}
      >
        <div className="navbar-brand level-left">
          <Link className="navbar-item level-item" to="/">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/algrof-f67fb.appspot.com/o/ski-logo-print.png?alt=media&token=bdf510d9-a101-49f7-92bd-f57fa30e1936"
              alt="Algo.ski"
              width="30"
              className="mr-2"
            />
            <h1 className="title is-5 level-item">
              <span className="mobile-only" style={{ color: "white" }}>
                algo<span className="has-text-primary">.</span>ski
              </span>
              <span
                className="button is-small is-outlined is-static is-primary is-oulined has-text-primary level-item"
                style={{ marginLeft: "8px", height:"25px", border:"1px solid turquoise" }}
              >
                v.0.1
              </span>
            </h1>
          </Link>
        </div>
     {/* {Object.keys(users).map((user, i) => (
                <li key={i}>{users[user].username}</li>
      ))} */}

        {/* <span className="navbar-menu mobile-goner">
          <NavLink
            className="navbar-item level-item has-text-white letterspacing"
            style={{ marginLeft: "5px" }}
            to={"/earn"}
          >
            <strong>EARN</strong>
          </NavLink>
          <NavLink
            className="navbar-item level-item has-text-white letterspacing"
            to={"/fairdrop/ski"}
          >
            <strong>FAIRDROP</strong>
          </NavLink>
          <NavLink
            className="navbar-item level-item has-text-white letterspacing"
            to={"/verify"}
          >
            <strong>VERIFY</strong>
          </NavLink>
      
        </span> */}
        <div>
          <div className="navbar-end">
            <div className="navbar-item nohover">
              <div className="level-right">
                
              <div class="mobile-search-goner">
            
               <Search />
           
            </div>

                {connectedNav}
                {/* <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false">
  <span aria-hidden="true"></span>
  <span aria-hidden="true"></span>
  <span aria-hidden="true"></span>
</a> */}
              </div>
            </div>
          </div>
        </div>
      </nav>
      {/* <div className="desktop-goner">
        <div
          className="columns is-flex"
          style={{ backgroundColor: "#41413f", marginTop: "-25px" }}
        >
          <div className="column hovah is-one-third">
            <NavLink
              className="level-item has-text-white letterspacing"
              to={"/earn"}
            >
              <strong>EARN</strong>
            </NavLink>
          </div>
          <div className="column hovah is-one-third">
            <NavLink
              className="level-item has-text-white letterspacing"
              to={"/fairdrop/ski"}
            >
              <strong>FAIRDROP</strong>
            </NavLink>
          </div>
          <div className="column hovah is-one-third">
            <NavLink
              className="level-item has-text-white letterspacing"
              to={"/verify"}
            >
              <strong>VERIFY</strong>
            </NavLink>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default (props) => <Header {...props} params={useParams()} />;
