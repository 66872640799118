import React, {Component} from "react";
import { Link } from "react-router-dom";
import "../main.css";
import { useState, useEffect } from "react";
import Masonry from "react-masonry-css";
import axios from "axios";

const breakpointColumnsObj = {
    default: 5,
    1500: 4,
    900: 3,
    700: 2,
    421: 1
  };

const nfts = [
    { 'assetID': '1', 'name': 'AAA', 'url': 'https://picsum.photos/400/300' },
    { 'assetID': '2', 'name': 'BBB', 'url': 'https://picsum.photos/400/300' },
    { 'assetID': '3', 'name': 'CCC', 'url': 'https://picsum.photos/400/300' },
  ];
  

const config = {
    method: "GET",
    url:
      "https://algoexplorerapi.io/v2/accounts/" +
      '6CFAJRDH2KGMDHYZ3BQS4BIEY3URFFP5XTFRJKJX7MFRNJU7DHAH77HJQI'
        }

  export default class Gal extends Component {
    state = {
        algoAddress: "",
      };

      
    
  
    render(){


  return (
    <div>
    <Masonry
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
            breakpointCols={breakpointColumnsObj}
        >
            {[
            ...nfts.map((nft) => (
                <div className="card" style={{ marginBottom: "15px" }}>
                <div className="card-header">
                    <div className="card-header-title">
                    <h1>
                        <strong>{nft.name}</strong>
                    </h1>
                    </div>
                    <div className="card-header-icon">
                    <img
                        style={{ marginLeft: "5px", width: "14px" }}
                        alt="Verified asset"
                        src="https://cdn3.iconfinder.com/data/icons/essentials-volume-i/128/verified-blue-512.png"
                    />
                    </div>
                </div>

                <div className="card-image">
                    {nft.url.startsWith("ipfs") && (
                    <img
                        src={"https://ipfs.io/ipfs/" + nft.url.slice(7)}
                        alt="NFT"
                    />
                    )}
                    {nft.url.startsWith("https") && <img src={nft.url} alt="NFT" />}
                    {/* <img src={nft.url} alt="NFT" /> */}
                </div>
                <div
                    className="card-content"
                    style={{ display: "flex", padding: "10px 0px 10px 10px" }}
                >
                    <p className="content subtitle is-6">
                    {/* {nft.assetId} */}
                    <a
                        href={"https://www.nftexplorer.app/asset/" + nft.assetId}
                        className="button is-small is-text"
                        style={{ marginRight: "10px" }}
                    >
                        {" "}
                        View on NFT Explorer
                    </a>
                    </p>
                </div>
                </div>
            )),

        
            ]}
        </Masonry>

        </div>
            );
            }
        }
