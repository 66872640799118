import React, { useState, useCallback } from "react"
import EmojiPicker from "./EmojiPicker"
import EmojiReactionButton from "./EmojiReactionButton"

export default function Reaction() {
	let [selectedEmojis, setSelectedEmojis] = useState<string[]>([])
	let [emojiPickerOpen, setEmojiPickerOpen] = useState(false)

	let onToggleEmojiPicker = useCallback(() => {
		setEmojiPickerOpen(emojiPickerOpen => !emojiPickerOpen)
	}, [])

	let onCloseEmojiPicker = useCallback(() => {
		setEmojiPickerOpen(false)
	}, [])

	let onRemoveEmoji = useCallback((emoji: string) => {
		setSelectedEmojis(selectedEmojis => {
			return selectedEmojis.filter(selectedEmoji => selectedEmoji !== emoji)
		})
	}, [])

	let onToggleEmoji = useCallback((emoji: string, event: React.MouseEvent) => {
		setSelectedEmojis(selectedEmojis => {
			if (selectedEmojis.includes(emoji)) {
				return selectedEmojis.filter(selectedEmoji => selectedEmoji !== emoji)
			} else {
				return [...selectedEmojis, emoji]
			}
		})

		if (!event.shiftKey) {
			setEmojiPickerOpen(false)
		}
	}, [])

	return (
		<div>
			<div>
				{selectedEmojis.map(selectedEmoji => {
					return (
						<EmojiReactionButton
							label={selectedEmoji}
							icon={selectedEmoji}
							count={1}
							onClick={() => {
								onRemoveEmoji(selectedEmoji)
							}}
						/>
					)
				})}
				<EmojiReactionButton
					label="Open Emoji Picker"
					icon="＋☺"
					onClick={onToggleEmojiPicker}
				/>
			</div>

			{emojiPickerOpen && (
				<EmojiPicker
					selectedEmojis={selectedEmojis}
					onToggleEmoji={onToggleEmoji}
					onClose={onCloseEmojiPicker}
				/>
			)}
		</div>
	)
}
