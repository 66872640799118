import React, { Component } from 'react';

import { shuffleAnswers } from '../actions/answerActions';
import Question from '../components/Question';
import Summary from '../components/Summary';
import ProgressionBar from '../components/ProgressionBar';
import { getQuizzes, getMoreQuizzes } from '../data/quizzes';

class QuizContainer extends Component {
  state = {
    quizActive: true,
    currentQuiz: 0,
    currentQuestion: 0,
    currentAnswers: [],
    attemptedQuestions: 0,
    score: 0,
    quizzes: [],
  };

  componentDidMount() {
    getQuizzes().then((quizzes) => {
      this.setState({
        quizzes: quizzes,
        currentAnswers: [
          // below shuffles the answers on the first question of the first quiz
          ...shuffleAnswers(
            quizzes[0].questions[0].correctAnswer,
            quizzes[0].questions[0].incorrectAnswers,
          ),
        ],
      });
    });
  }

  incrementAttemptedQuestions = () => {
    this.setState({
      attemptedQuestions: this.state.attemptedQuestions + 1,
    });
  };

  incrementScore = () => {
    this.setState({
      score: this.state.score + 1,
    });
  };

  nextQuestion = () => {
    this.setState({
      currentQuestion: this.state.currentQuestion + 1,
      // below shuffles the answers of the next question on the current quiz
      currentAnswers: shuffleAnswers(
        this.state.quizzes[this.state.currentQuiz].questions[
          this.state.currentQuestion + 1
        ].correctAnswer,
        this.state.quizzes[this.state.currentQuiz].questions[
          this.state.currentQuestion + 1
        ].incorrectAnswers,
      ),
    });
  };

  completeQuiz = () => {
    this.setState({
      quizActive: !this.state.quizActive,
    });
  };

  completeTasky = (task,taskvalue) => {
    this.props.complete(task,taskvalue);
  };

  nextQuiz = () => {
    this.setState({
      attemptedQuestions: 0,
      score: 0,
      currentQuiz: this.state.currentQuiz + 1,
      currentQuestion: 0,
      // below shuffles the answers on the first question of the next quiz
      currentAnswers: shuffleAnswers(
        this.state.quizzes[this.state.currentQuiz + 1].questions[0]
          .correctAnswer,
        this.state.quizzes[this.state.currentQuiz + 1].questions[0]
          .incorrectAnswers,
      ),
      quizActive: !this.state.quizActive,
    });
  };

  moreQuizzes = () => {
    this.setState({
      quizzes: [],
    });

    getMoreQuizzes().then((quizzes) => {
      this.setState({
        attemptedQuestions: 0,
        score: 0,
        currentQuiz: 0,
        currentQuestion: 0,
        // below shuffles the answers on the first question of the first quiz
        currentAnswers: [
          ...shuffleAnswers(
            quizzes[0].questions[0].correctAnswer,
            quizzes[0].questions[0].incorrectAnswers,
          ),
        ],
        quizzes: quizzes,
        quizActive: !this.state.quizActive,
      });
    });
  };

  restartQuiz = () => {
    this.setState({
      quizzes: [],
    });
    
    getQuizzes().then((quizzes) => {
      this.setState({
        attemptedQuestions: 0,
        score: 0,
        currentQuiz: 0,
        currentQuestion: 0,
        // below shuffles the answers on the first question of the first quiz
        currentAnswers: [
          ...shuffleAnswers(
            quizzes[0].questions[0].correctAnswer,
            quizzes[0].questions[0].incorrectAnswers,
          ),
        ],
        quizzes: quizzes,
        quizActive: !this.state.quizActive,
      });
    });
  };

  render() {
    if (!this.state.quizzes.length) {
      return <h1>loading...</h1>;
    } else {
      return (
        <>
          {this.state.quizzes.length && (
            <h1 className="mb-4 is-size-4 has-text-white"><br/><hr/><span className="title is-size-4 has-text-bold has-text-primary"><span className="has-text-white title is-size-4">Take the quiz: </span>{this.state.quizzes[this.state.currentQuiz].title}</span></h1>
          )}

          {this.state.quizActive === true && (
            <Question
              currentQuiz={this.state.currentQuiz}
              currentQuestion={this.state.currentQuestion}
              currentAnswers={this.state.currentAnswers}
              quizzes={this.state.quizzes}
              incrementAttemptedQuestions={this.incrementAttemptedQuestions}
              incrementScore={this.incrementScore}
              nextQuestion={this.nextQuestion}
              completeQuiz={this.completeQuiz}
            />
          )}
          {this.state.quizActive === false && (
            <Summary
              quizzes={this.state.quizzes}
              attemptedQuestions={this.state.attemptedQuestions}
              score={this.state.score}
              currentQuiz={this.state.currentQuiz}
              nextQuiz={this.nextQuiz}
              moreQuizzes={this.moreQuizzes}
              restartQuiz={this.restartQuiz}
              completeTasky={this.completeTasky}
            />
          )}

          {/* <ProgressionBar
            quizzes={this.state.quizzes}
            attemptedQuestions={this.state.attemptedQuestions}
            score={this.state.score}
            currentQuiz={this.state.currentQuiz}
            currentQuestion={this.state.currentQuestion}
          /> */}
        </>
      );
    }
  }
}

export default QuizContainer;
