import React from "react"

export interface EmojiReactionButtonProps {
	label: string
	icon: string
	count?: number
	onClick: () => void
}

export default function EmojiReactionButton(props: EmojiReactionButtonProps) {
	return (
		<div
            className="tag mr-1 is-dark"
			aria-label={props.label}
			onClick={props.onClick}
			
		>
			{props.icon}
			{props.count && (
				<span
				>
					{props.count}
				</span>
			)}
		</div>
	)
}
