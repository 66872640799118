import React from "react";
import Header from "./components/Nav";
import Footer from "./components/Footer";
import Homepage from "./routes/Homepage";
import Users from "./routes/Users";
import Homeski from "./routes/Earn";
import Algoski from "./routes/Algoski";
import Profile from "./routes/Profile";
import Verify from "./routes/Verify";
import Project from "./routes/Project";
import Roadmap from "./routes/Roadmap";
import Airdrop from "./routes/Fairdrop";
import { Route, Routes } from "react-router-dom";
import Headroom from "react-headroom";
import { useLocalState } from "./lib/hooks";
import ScrollToTop from "./lib/scrolltotop";
import { Helmet, HelmetProvider } from 'react-helmet-async';



export const Context = React.createContext();

export default function App() {
  const [myAddress, setmyAddress] = useLocalState("myAddress");
  return (
    <div>
      <HelmetProvider>
        <Helmet>
        <meta charset="utf-8" />
    <meta
      name="description"
      content="algo.ski | Discover projects built on the Algorand blockchain and earn cryptoassets."
    />
    <link rel="apple-touch-icon" href="%PUBLIC_URL%/apple-touch-icon.png" />

    <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
 
    <title>algo.ski | Verified humans at work.</title>
 
        </Helmet>
      <Context.Provider value={[myAddress, setmyAddress]}>
        <Headroom>
          <Header />
        </Headroom>
        <ScrollToTop>
          <Routes>
          <Route path="/algoski" element={<Algoski />} />

            <Route path="/" element={<Homepage />} />
            {/* <Route path="/earn" element={<Homeski />} /> */}
            <Route path="u/:username" element={<Profile />} />
            <Route path="users" element={<Users />} />
            {/* <Route path="r/:room" element={<Room />} />
          <Route path="c/" element={<ChatRoom />} /> */}
            {/* <Route path="earn/:username" element={<Project />} /> */}
            <Route path="roadmap" element={<Roadmap />} />
            {/* <Route path="fairdrop/:username" element={<Airdrop />} /> */}
            <Route path="verify" element={<Verify />} />

          </Routes>{" "}
        </ScrollToTop>
        <Footer />
      </Context.Provider>
      </HelmetProvider>
    </div>
  );
}
