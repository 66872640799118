import React from 'react';
// import { quizzes } from '../data/quizzes';
import { getMessage } from '../data/messages';
import { completeTask } from '../../../routes/Project';

import './Summary.css';

class Summary extends React.Component {
    constructor(props) {
      super(props);
    

    this.state = {
        quizzes: props.quizzes,
        attemptedQuestions: props.attemptedQuestions,
        score: props.score,
        task: props.task,
        taskvalue: props.taskvalue,
        completeTasky: props.completeTasky,
        complete: props.complete,
        currentQuiz: props.currentQuiz,
        nextQuiz: props.nextQuiz,
        moreQuizzes: props.moreQuizzes,
        restartQuiz: props.restartQuiz
      };
    }

    render() {
  return (
    <>
      <div className="content">
        <p className="title has-text-white is-5">
          You got <span>{this.props.score}</span> of <span>{this.props.attemptedQuestions}</span>{' '}
          questions right.
        </p>
        {/* <p>{getMessage()}</p> */}
      </div>

      <div className="post-summary-container">
        {this.props.score === 4 ? (
          <>
            <button className="button mb-4 is-primary has-text-black" onClick={this.props.completeTasky}>
              Complete task
            </button>
          </>
        ) : (
            <button className="button mb-4 is-warning" onClick={this.props.restartQuiz}>
              Try again
            </button>
        )}
      </div>
    </>
  );
};}

export default Summary;
