export const shuffleAnswers = (correctAnswer, incorrectAnswers) => {
    let answers = [];
    answers.push(...incorrectAnswers);
    answers.push(correctAnswer);
  
    let currentIndex = answers.length;
    let temporaryValue, randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      temporaryValue = answers[currentIndex];
      answers[currentIndex] = answers[randomIndex];
      answers[randomIndex] = temporaryValue;
    }
  
    return answers;
  };
  