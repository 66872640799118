import React from "react";
import PieChart from "../components/PieChart";
import { Helmet } from "react-helmet-async";
import "../main.css";

export default function Roadmap() {
  return (
      <div>
           <Helmet>
        <meta charset="utf-8" />
    <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="theme-color" content="#000000" />
    <meta
      name="description"
      content="Roadmap | algo.ski"
    />
    <link rel="apple-touch-icon" href="%PUBLIC_URL%/apple-touch-icon.png" />

    <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
 
    <title>algo.ski | Roadmap</title>
 
        </Helmet>
           <div
        class="hero is-dark"
        style={{
          backgroundImage: `url("https://lh3.googleusercontent.com/proxy/XF7qhYDlzqqNILC90co9oYtmEirlCqRTiZQ177ZQ3hcs3fE0a_ejqYUERrF3O6ryD1uyiivZK23UUImlv7SUEQSH")`,
          objectFit: "cover",
        }}
      >
                    <div className="container">

        <div class="hero-body">
          <p class="title">algo<span className="has-text-primary">.</span>ski</p>
          <p class="subtitle is-6">We're on a mission to provide the universal standard for <strong>identity</strong> on the Algorand blockchain. We build products to simplify user experiences and help fortify the ecosystem by verifying real human beings (not sophisticated scripts and bad actors). All of your verification data is encrypted with Bcrypt and thus can never be shared, stored, or used to spam you with marketing campaigns. We offer a public API so other teams can skip the hassle of ensuring their governance votes, airdrops, and more are 100% verifiably human and a dApp for performing these functions online without writing a single line of code. This is only the beginning. 🌈🦄</p>
        </div></div>
      </div>
    <div className="container mb-6">
     
      <br />
      <br />
      <div className="columns">
          <div className="column is-narrow is-5">
        <div class="section content">
          <h1 className="has-text-white">In progress</h1>

          <ul className="has-text-white mb-4">
            <li>Verified universal profiles using Algorand blockchain.</li>
            <li>Learn & Earn use-case demonstration.</li>
            <li>VERIFY development SDK.</li>
            <li>Design & Branding</li>
          </ul>

          <h1 className="has-text-white">Soon</h1>

          <ul className="has-text-white mb-6">
            <li>VERIFY Secure via ASC1 application.</li>
            <li>Fairdrop ASC1 application.</li>
            <li>Onboard campaign partner projects.</li>
            <li>Verifiably fair giveaway use-case demonstration</li>
          </ul>

          <h1 className="has-text-white">One day</h1>

          <ul className="has-text-white mb-6">
            <li>Launch algo<span className="has-text-primary">.</span>ski Treasury.</li>
            <li>SKI staking to earn ASAs from Treasury RP.</li>
            <li>SKI Governance.</li>
            <li>
              Do you have ideas and/or want to help us get to where we're going?{" "}
              <a href="mailto:hello@algo.ski">contact us</a>
            </li>
          </ul>
        </div>
</div>
        <div className="column">
                 <div class="section ml-4">
          <h1 className="has-text-white">SKI token<p className="is-size-6 subtitle">Asset ID: 450932900</p></h1>
        <p className="title is-5 has-text-white">Why does algo<span className="has-text-primary">.</span>ski have a token anyway?</p>
        <p className=" is-6 mb-4 has-text-grey-light">The SKI token is currently used for the purpose of demonstrating the functionality of our application. Future plans involve utilizing SKI as an added incentive for completing tasks and participating in the ecosystem.</p>
       <br/> <div className="buttons">
            <button className="button is-primary has-text-black" disabled>Whitepaper</button>
            <button className="button is-primary has-text-black" disabled>Pitch deck</button>
            <button className="button is-primary has-text-black" disabled>Airdrop</button> 
        </div>
        <p className="has-text-grey">More details to be announced Q1 2022 via <a href="https://twitter.com/algodotski">Twitter</a></p>

                {/* <div className=""><PieChart /></div> */}
                </div>
            </div>
        </div>
      </div></div>
  );
}
