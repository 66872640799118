import firebase from 'firebase/compat/';
import 'firebase/app';
import "firebase/database";
import "firebase/storage";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: "algrof-f67fb",
  storageBucket: "algrof-f67fb.appspot.com",
  messagingSenderId: "652203131280",
  appId: "1:652203131280:web:3e6c46eb62ca68587a7315"
};

export default firebase.initializeApp(config);
