import React, { Component, useEffect, useState, useLocation } from "react";
import firebaseApp from "../firebase";
import axios from "axios";
import QuizContainer from "../components//quiz/containers/QuizContainer";
import Reaction from "../components/Reaction.tsx";
import InputCode from "../components/InputCode";
import Gal from "../components/Gal";
import { useParams, withRouter, useHistory } from "react-router-dom";
import Pipeline from "@pipeline-ui-2/pipeline"; //change to import Pipeline from 'Pipeline for realtime editing Pipeline index.js, and dependency to: "Pipeline": "file:..",
import "bulma/css/bulma.min.css";
import PhoneInput from "react-phone-number-input";
import us from "react-phone-number-input/";
import "../main.css";
import {
  getAllAssetsOfAddress,
  getAllNonZeroAssetsOfAddress,
  sendAssetToAddress,
} from "../lib/algohelpers.ts";
import { NFT } from "../lib/nft.ts";
import Tooltip from "../components/Tooltip";
import FileUploader from "react-firebase-file-uploader";
import { LinkForm, LinkList } from "../components/LinkForm";
import { LinkListDisplay } from "../components/LinkListDisplay";
import MDEdit from "../components/MDEdit";
import MDEditor from "@uiw/react-md-editor";

//change recipient address before sending transaction

const cryptrSecret = process.env.REACT_APP_CRYPTR_SECRET;
const myAlgoWallet = Pipeline.init();
const WalletConnect = Pipeline.init();

const Cryptr = require('cryptr');
const cryptr = new Cryptr(`${cryptrSecret}`);

const myAddress = localStorage.getItem("myAddress");
//amount in micro Algos
const amount = 0;
const note = "";

// export const getQuizzes = (props) => {
//     const quizzes = [
//         {
//           title: props.task.taskName,
//           questions: props.task.questions,
//         },
//       ];
//     console.log(quizzes);
//   };

Pipeline.main = true;
var mynet = Pipeline.main ? "MainNet" : "TestNet";

class Project extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      myAddress: "",
      color: "none",
      balance: "n/a",
      net: mynet,
      txID: "",
      username: "",
      verifyInput: "",
      myAssets: "",
      myNonZeroAssets: "",
      dbUsername: "",
      displayImage: "",
      actualEarnings: "",
      sendWait: false,
      progress: 0,
      isUploading: false,
      coverImage: "",
      gotIt: false,
      projectName: "",
      score: "",
      assetName: "",
      totalClaimedForAsset: "",
      users: "",
      projects: "",
      bio: "",
      isVerified: "",
      dbBio: "",
      slug: "",
      isModal: false,
      isModalProf: false,
      algoAddress: this.props.params.username,
      ivoted: false,
      linksList: [],
      projectInfo: "",
      completedTasks: [],
      claims: [],
      assetId: "",
      postChild: false,
      posts: [
        {
          post: {
            content: "",
            timestamp: "",
          },
        },
      ],
      tasks: [],
    };
    this.completeTask = this.completeTask.bind(this);
  }
  completeTask = (task, taskvalue) => {
    const myAddress = localStorage.getItem("myAddress");
    const completedTask = task;
    const completedTaskValue = taskvalue;
    this.dbRef
      .ref("/users/" + myAddress)
      .child("completedTasks")
      .child(this.state.algoAddress)
      .push({ completedTask, completedTaskValue });
  };

  addLinkToState = (linkSite, linkUrl) => {
    let toBeAddedActionItem = {
      linkSite: linkSite,
      linkUrl: linkUrl,
    };
    if (this.prevState) {
      this.setState((prevState) => ({
        linksList: prevState.linksList.concat(toBeAddedActionItem),
      }));
    }
  };
  deleteActionItemFromState = (index) => {
    const linksList = [...this.state.linksList];
    linksList.splice(index, 1);
    this.setState({ linksList });
  };

  handleChangeUsername = (event) =>
    this.setState({ username: event.target.value });
  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
  handleProgress = (progress) => this.setState({ progress });
  handleUploadError = (error) => {
    this.setState({ isUploading: false });
    console.error(error);
  };
  handleUploadSuccess = (filename) => {
    this.setState({ avatar: filename, progress: 100, isUploading: false });
    firebaseApp
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL()
      .then((url) => this.setState({ displayImage: url }));
  };

  handleUploadStartCover = () =>
    this.setState({ isUploading: true, progress: 0 });
  handleProgressCover = (progress) => this.setState({ progress });
  handleUploadErrorCover = (error) => {
    this.setState({ isUploading: false });
    console.error(error);
  };
  handleUploadSuccessCover = (filename) => {
    this.setState({ avatar: filename, progress: 100, isUploading: false });
    firebaseApp
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL()
      .then((url) => this.setState({ coverImage: url }));
  };

  dbRef = firebaseApp.database();
  updateDB = this.updateDB.bind(this);

  updateDB() {
    const myAddress = this.state.algoAddress;
    const color = this.state.color;
    const username = this.state.dbUsername;
    const bio = this.state.dbBio;
    const coverImage = this.state.coverImage;
    const links = this.state.linksList;
    const displayImage = this.state.displayImage;
    this.dbRef.ref("/users/").child(myAddress).set({
      myAddress,
      color,
      username,
      bio,
      coverImage,
      displayImage,
      links,
    });
    this.setState({ isModalProf: !this.state.isModalProf });
  }

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     net: mynet,
  //     txID: "",
  //     myAddress: "",
  //     balance: 0,
  //     color: "color"
  //   };
  // }

  fetchBalance = () => {
    Pipeline.balance(myAddress).then((data) => {
      this.setState({ balance: data });
      console.log(this.state.balance);
    });
  };

  updateAddy() {
    const myAddress = localStorage.getItem("myAddress");
    this.dbRef.ref("/users/").child(myAddress).update({ myAddress });
  }

  handleConnect = () => {
    Pipeline.pipeConnector = "myAlgoWallet";
    Pipeline.connect(myAlgoWallet).then((data) => {
      this.setState({ myAddress: data });
      localStorage.setItem("myAddress", data);
      this.updateAddy();
      window.location.href = "/earn/" + this.state.slug.split(".").join("");
      console.log(this.state.myAddress);
    });
  };

  handleConnectOfficial = () => {
    Pipeline.pipeConnector = "WalletConnect";
    Pipeline.connect(WalletConnect).then((data) => {
      this.setState({ myAddress: data });
      localStorage.setItem("myAddress", data);
      localStorage.setItem("WC", true);
      this.updateAddy();
      window.location.href =
        "/earn/" + this.state.projectName.split(".").join("");
    });
  };

  handleConnectOptIn = () => {
    Pipeline.connect(myAlgoWallet).then((data) => {
      this.setState({ myAddress: data });
      this.handleSend();
    });
  };

  handleConnectOfficialOptIn = () => {
    this.handleSendOfficial();
  };

  handleSend = () => {
    Pipeline.send(
      myAddress,
      amount,
      note,
      this.state.myAddress,
      myAlgoWallet,
      this.state.assetId
    ).then((data) => {
      this.setState({ txID: data });
    });

    console.log(myAddress);
  };

  handleSendOfficial = () => {
    Pipeline.send(
      myAddress,
      amount,
      note,
      this.state.myAddress,
      WalletConnect,
      this.state.assetId
    ).then((data) => {
      this.setState({ txID: data });
    });

    console.log(myAddress);
  };

  toggleChild = () => {
    this.setState((prevState) => ({
      postChild: !prevState.postChild,
    }));
  };

  Child = ({ toggleChild }) => {
    return (
      <div>
        <input className="input is-rounded" />
        <div className="buttons mt-2">
          <button className="button mt-2 is-small is-primary">Post</button>
        </div>
      </div>
    );
  };

  switchConnector = (event) => {
    Pipeline.pipeConnector = event.target.value;
  };

  colorPicker = (event) => {
    this.setState({ color: event.target.value });
  };

  //   componentDidUpdate() {
  //       console.log('updated');
  //       this.getClaims();
  //   }

  getAssets = () => {
    const assetz = getAllAssetsOfAddress(localStorage.getItem("myAddress"));
    Promise.resolve(assetz).then((value) => {
      this.setState({ myAssets: value });
    });
    Promise.reject(assetz).catch((error) => {
      console.log(error);
    });
  };

  getNonZeroAssets = () => {
    const assetzi = getAllNonZeroAssetsOfAddress(
      localStorage.getItem("myAddress")
    );
    Promise.resolve(assetzi).then((value) => {
      this.setState({ myNonZeroAssets: value });
    });
    Promise.reject(assetzi).catch((error) => {
      console.log(error);
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.totalClaimedForAsset !== this.state.totalClaimedForAsset) {
      console.log("updated");
      this.getClaims();
      this.getCompletedTasks();
      this.getAssets();
      this.getNonZeroAssets();
    }
    if (prevState.isVerified !== this.state.isVerified) {
      this.getVerifyStatus();
    }
  }

  handleCallback = (childData) => {
    console.log(this);
    this.setState({ score: childData });
  };

  sendThisAssetToAddress = (actualEarnings) => {
    this.setState({ sendWait: true });
    const assetId = this.state.assetId;
    const assetName = this.state.assetName;
    sendAssetToAddress(assetId, actualEarnings, assetName);
    const earns = actualEarnings;
    this.dbRef
      .ref("/users/" + localStorage.getItem("myAddress"))
      .child("claims")
      .child(this.state.algoAddress)
      .push({ earns });
    setTimeout(function () {
      window.location.reload(false);
      this.setState({ sendWait: false });
    }, 3000);
  };

  getCompletedTasks = () => {
    let ref = firebaseApp
      .database()
      .ref(
        "/users/" +
          localStorage.getItem("myAddress") +
          "/completedTasks/" +
          this.state.algoAddress
      );
    ref.on("value", (snapshot) => {
      const completes = snapshot.val();
      if (completes) {
        this.setState({
          completedTasks: completes,
        });
      }
    });
  };

  getClaims = () => {
    let ref = firebaseApp
      .database()
      .ref(
        "/users/" +
          localStorage.getItem("myAddress") +
          "/claims/" +
          this.state.algoAddress
      );
    ref.on("value", (snapshot) => {
      const claimsForAsset = snapshot.val();
      if (claimsForAsset) {
        this.setState({
          claims: claimsForAsset,
        });
      }
      this.setState({
        totalClaimedForAsset: Object.values(this.state.claims).reduce(
          (r, { earns }) => r + earns,
          0
        ),
      });
    });
  };

  getVerifyStatus = () => {
    let ref = firebaseApp
      .database()
      .ref("/users/" + localStorage.getItem("myAddress"));
    ref.on("value", (snapshot) => {
      const users = snapshot.val();
      if (users) {
        this.setState({
          isVerified: users.isVerified,
        });
      }
    });
  };

  componentDidMount() {
    let ref = firebaseApp.database().ref("/projects/" + this.state.algoAddress);
    ref.on("value", (snapshot) => {
      const projects = snapshot.val();
      if (projects) {
        this.setState({
          projects: true,
          projectInfo: projects.projectInfo,
          username: projects.username,
          displayImage: projects.displayImage,
          coverImage: projects.coverImage,
          assetId: projects.assetId,
          assetName: projects.assetName,
          projectName: projects.projectName,
          linksList: projects.links,
          posts: projects.posts,
          tasks: projects.tasks,
          isEligible: projects.isEligible,
          slug: projects.slug,
        });
      }
      const { id } = this.props.params;
      if (localStorage.getItem("myAddress")) {
        this.getCompletedTasks();
        this.getClaims();
        this.getVerifyStatus();
        const assetz = getAllAssetsOfAddress(localStorage.getItem("myAddress"));
        const assetzi = getAllNonZeroAssetsOfAddress(
          localStorage.getItem("myAddress")
        );

        Promise.resolve(assetz).then((value) => {
          this.setState({ myAssets: value });

          console.log(this.state.isVerified);
        });
        Promise.resolve(assetzi).then((value) => {
          this.setState({ myNonZeroAssets: value });
        });
      }
    });
  }

  clearStorage = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  handleClick = () => {
    this.setState({ isModal: !this.state.isModal });
  };

  verifySMS = () => {
    var axios = require("axios");

    var config = {
      method: "post",
      url:
        "https://verify-sample-5247-dev.twil.io/start-verify?to=%2B1" +
        this.state.phone +
        "&locale=en&channel=sms",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Basic " + process.env.REACT_APP_TWILIO_AUTH_TOKEN,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
    this.setState({ loading: true });
  };

  checkVerifySMS = (code) => {
    var axios = require("axios");

    var config = {
      method: "post",
      url:
        "https://verify-sample-5247-dev.twil.io/check-verify?to=%2B1" +
        this.state.phone +
        "&verification_code=" +
        code,
      headers: {
        "Content-Type": "application/json",
        Authorization:
        "Basic " + process.env.REACT_APP_TWILIO_AUTH_TOKEN,
          },
    };

    axios(config)
      .then((response) => {
        console.log(response);
        const res = response.data;
        const verifiedStatus = res.success;
        this.setState({ verifySuccess: verifiedStatus });
        if (verifiedStatus) {
          this.updateVerify();
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ verifyError: true });
        setTimeout(() => this.setState({ verifyError: false }), 4000);
      });
  };

  handleVerifyInput = (event) =>
    this.setState({ verifyInput: event.target.value });

  updateVerify() {
    const myAddress = localStorage.getItem("myAddress");
    this.dbRef.ref("/users/").child(myAddress).update({ isVerified: "yes", verifiedSMS: cryptr.encrypt(this.state.phone) });
    setTimeout(
      () => this.setState({ isModalVerify: !this.state.isModalVerify }),
      2000
    );
  }

  handleClickVerify = () => {
    this.setState({ isModalVerify: !this.state.isModalVerify });
  };

  handleClickProf = () => {
    this.setState({ isModalProf: !this.state.isModalProf });
  };

  handleUsernameChange = (e) => this.setState({ dbUsername: e.target.value });
  handleBioChange = (e) => this.setState({ dbBio: e.target.value });

  render() {
    // const [uploadedImage, setUploadedImage] = useState(null);
    // const [imageSrc, setImageSrc] = useState("");

    // const handleUpload = async () => {
    //   const formData = new FormData();
    //   formData.append("file", uploadedImage);
    //   formData.append("upload_preset", "xuit9n89");

    //   const res = await axios.post("https://api.cloudinary.com/v1_1/dgg6qf2qy/image/upload", formData);
    //   // store image url in db.
    //   setImageSrc(res.data.url);
    // }

    // this.response.data.map(group => Object.keys(group.price).map(priceOpt => console.log(priceOpt, group.price[priceOpt])));

    const active = this.state.isModal ? "is-active" : "";
    const activeProf = this.state.isModalProf ? "is-active" : "";
    const activeVerify = this.state.isModalVerify ? "is-active" : "";

    var myAsas = this.state.myAssets;
    var myNonZeroAsas = this.state.myNonZeroAssets;

    var isConnected;
    if (this.state.myAddress === "Connect your wallet") {
      isConnected = (
        <h2 className="subtitle is-7">
          <strong>{this.state.assetName}</strong>
          <br /> Asset ID #{this.state.assetId}
        </h2>
      );
    } else {
      isConnected = (
        <h2 className="subtitle is-7">
          <strong>{this.state.assetName}</strong>
          <br /> Asset ID #{this.state.assetId}
        </h2>
      );
    }

    var isOwnedWallet;
    if (this.state.myAddress === this.state.algoAddress) {
      isOwnedWallet = (
        <span
          className="button is-size-6 is-dark ml-2 is-text level-item"
          onClick={this.handleClickProf}
        >
          {" "}
          edit profile
        </span>
      );
    } else if (localStorage.getItem("myAddress") === this.state.algoAddress) {
      isOwnedWallet = (
        <span
          className="button is-size-6 is-text ml-2 is-dark level-item"
          onClick={this.handleClickProf}
        >
          {" "}
          edit profile
        </span>
      );
    } else if (localStorage.getItem("myAddress") === null) {
      isOwnedWallet = (
        <span
          className="ml-2 button is-small is-size-7 is-text is-dark level-item has-text-white"
          onClick={this.handleClick}
        >
          {" "}
          connect your wallet to edit
        </span>
      );
    }

    var cover;
    if (this.state.coverImage) {
      cover = `url("${this.state.coverImage}")`;
    } else {
      cover = `url("https://picsum.photos/1000/400/?blur=5")`;
    }

    var hasLinksDisplay;
    if (this.state.linksList !== undefined) {
      hasLinksDisplay = (
        <LinkListDisplay
          actionItemsList={this.state.linksList}
          deleteActionItemFromState={this.deleteActionItemFromState}
          icon={this.state.displayImage}
        />
      );
    }

    var hasLinks;
    if (this.state.linksList) {
      hasLinks = (
        <LinkList
          actionItemsList={this.state.linksList}
          deleteActionItemFromState={this.deleteActionItemFromState}
        />
      );
    }

    var hasPosts;
    if (this.state.tasks) {
      hasPosts = Object.entries(this.state.tasks).map(([key, task]) => {
        const taskKey = key;

        return (
          <div key={taskKey}>
            <ul>
              <div className="box pl-0 pr-0 pt-3 pb-3 mb-3" style={{ backgroundColor: "#1b1b1a" }}>
                <header
                  class="card-header"
             
                >
                  <div className="card-header-title">
                    <figure className="ml-1 image is-24x24">
                      <img
                        className="border is-rounded"
                        alt="Avatar"
                        src={
                          this.state.displayImage
                            ? this.state.displayImage
                            : "https://media1.giphy.com/media/3o7bu3XilJ5BOiSGic/giphy.gif"
                        }
                      />
                    </figure>
                    <p className="ml-2" style={{ color: "white" }}>
                      {task.taskName}
                    </p>
                    <p
                      className="ml-4 subtitle is-7"
                      style={{ color: "#ebebeb" }}
                    >
                      {task.assetName}
                    </p>
                  </div>
                  <div class="card-header-icon">
                    {Object.values(this.state.completedTasks).some(
                      (item) => item.completedTask === task.taskName
                    ) ? (
                      <p className="has-text-primary subtitle is-6 mr-2">
                        Task completed
                      </p>
                    ) : (
                      <p className="has-text-warning subtitle is-6 mr-2">
                        Earn {task.taskValue} {this.state.assetName}
                      </p>
                    )}
                    {/* <p className="tag is-small is-dark">125/{task.taskLimit} claimed</p> */}
                  </div>
                </header>
                {Object.values(this.state.completedTasks).some(
                  (item) => item.completedTask === task.taskName
                ) ? (
                  ""
                ) : (
                  <div className="card-content">
                    <MDEditor.Markdown
                      source={task.taskContent}
                      style={{ color: "white", fontFamily: "Inconsolata" }}
                    />
                    {task.type === "quiz" ? (
                      <QuizContainer
                        complete={() =>
                          this.completeTask(task.taskName, task.taskValue)
                        }
                        quizData={task.questions}
                      />
                    ) : (
                      ""
                    )}
                    {task.type === "file" ? (
                      <div>
                        <div>
                          {this.state.isUploading && (
                            <p className="has-text-black">Uploading...</p>
                          )}
                          <button class="mt-6 button is-dark has-text-semibold has-text-primary">
                            <span style={{ fontFamily: "Inconsolata" }}>
                              Upload file{" "}
                            </span>
                          </button>
                          <FileUploader
                            hidden
                            accept="image/*"
                            name="taskFile"
                            randomizeFilename
                            storageRef={firebaseApp.storage().ref("taskFiles")}
                            onUploadStart={this.handleUploadStart}
                            onUploadError={this.handleUploadError}
                            onUploadSuccess={this.handleUploadSuccess}
                            onProgress={this.handleProgress}
                          />
                        </div>
                        Upload a file to complete this task
                      </div>
                    ) : (
                      ""
                    )}
                    {task.type === "complete" ? (
                      <div className="mt-6">
                        <span
                          className={
                            localStorage.getItem("myAddress") ? "" : "is-hidden"
                          }
                        >
                          <button
                            className="button is-primary has-text-black"
                            onClick={() =>
                              this.completeTask(task.taskName, task.taskValue)
                            }
                          >
                            Complete task
                          </button>
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
                {/* {task.type === 'complete' ? <div>{this.handleUploadSuccess ? <div className="mt-6"><button className="button is-dark has-text-primary" onClick={() => this.completeTask(task.taskName,task.taskValue)}>Complete task</button></div> : <div className="mt-6"><button className="button is-dark has-text-primary" disabled>Complete task</button></div>} </div> : ""} */}

                {/* <footer class="card-footer"> */}
                  {/* <a href="#" className="level-left card-footer-item"><Reaction /></a> */}
                  {/* <p className="card-footer-item" style={{color:"#ebebeb"}}>Earn {task.taskValue} {this.state.assetName}</p>
                <p className="card-footer-item" style={{color:"#ebebeb"}}>Status:{' '} <i> incomplete</i></p> */}

                  {/* {(Object.values(this.state.completedTasks).some(item => item.completedTask === task.taskName)) ? <span className="card-footer-item"><strong style={{color:"white"}}>✅ Task completed</strong></span> : ""} */}
                  {/* {(Object.values(this.state.completedTasks).some(item => item.completedTask === task.taskName)) ? <span className="card-footer-item p-5"><strong style={{color:"white"}}>✅ Task completed</strong></span> : <span onClick={() => this.completeTask(task.taskName)} className="card-footer-item p-5"><a href="#"><strong className="has-text-primary">Complete task</strong></a></span>} */}
                {/* </footer> */}
                {this.state.postChild === false ? (
                  ""
                ) : (
                  <div className="p-4">
                    <div class="control has-icons-left has-icons-right">
                      <input
                        class="input is-small"
                        type="text"
                        placeholder={"reply to " + this.state.username}
                      />
                      <span class="icon is-small is-left">
                        <figure className="image is-16x16">
                          <img
                            className="border is-rounded"
                            alt="Avatar"
                            src={
                              this.state.displayImage
                                ? this.state.displayImage
                                : "https://media1.giphy.com/media/3o7bu3XilJ5BOiSGic/giphy.gif"
                            }
                          />
                        </figure>
                      </span>
                      <span class="icon is-small is-right">
                        <i class="fas fa-check"></i>
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </ul>
          </div>
        );
      });
    }

    var hasTasks;
    if (this.state.tasks) {
      hasTasks = Object.entries(this.state.tasks).map(([key, task]) => {
        const taskKey = key;
        return (
          <div key={taskKey}>
            <p className="mb-2">
              {Object.values(this.state.completedTasks).some(
                (item) => item.completedTask === task.taskName
              )
                ? "✅"
                : "🔲"}{" "}
              {task.taskName} <br />
              {Object.values(this.state.completedTasks).some(
                (item) => item.completedTask === task.taskName
              ) ? (
                <span className="is-size-7 ml-5">
                  💰 Earned {task.taskValue} {this.state.assetName}
                </span>
              ) : (
                <span className="is-size-7 ml-5">
                  🤑 Earn {task.taskValue} {this.state.assetName}
                </span>
              )}
            </p>
          </div>
        );
      });
    }
    const possibleEarnings = Object.values(this.state.tasks).reduce(
      (r, { taskValue }) => r + taskValue,
      0
    );
    const actualEarnings = Object.values(this.state.completedTasks).reduce(
      (r, { completedTaskValue }) => r + completedTaskValue,
      0
    );

    var optInTop;
    if (this.state.myAssets) {
      optInTop = (
        <div>
          {myAsas.includes(this.state.assetId) ? (
            ""
          ) : (
            <div
              className="message-content level"
              style={{
                color: "#fff",
                border: "1px solid yellow",
                borderRadius: "3px",
                padding: "10px 15px",
              }}
            >
              ⚠️ You must opt in to asset {this.state.assetId} before claiming
              your earned {this.state.assetName}.
              {this.state.txID ? (
                <p className="subtitle is-7">
                  <span className="has-text-white">Transaction ID: </span>{" "}
                  {this.state.txID}
                </p>
              ) : (
                <button
                  className="button is-small is-warning"
                  onClick={
                    localStorage.getItem("walletconnect")
                      ? this.handleConnectOfficialOptIn
                      : this.handleConnectOptIn
                  }
                >
                  Opt in
                </button>
              )}
            </div>
          )}
        </div>
      );
    }

    var hodl;
    if (this.state.myNonZeroAssets) {
      hodl = (
        <div>
          {myNonZeroAsas.includes(this.state.assetId) ? "letz go" : "letz no"}
        </div>
      );
    }

    var youClaimed;
    if (this.state.totalClaimedForAsset) {
      youClaimed = (
        <div>
          {this.state.totalClaimedForAsset > 0 ? (
            <span className="subtitle is-6 has-text-warning">
              You've claimed {this.state.totalClaimedForAsset}{" "}
              {this.state.assetName}
            </span>
          ) : (
            ""
          )}
        </div>
      );
    }

    var lockModal;
    if (!localStorage.getItem("myAddress")) {
      lockModal = (
          <div style={{width:"100%"}}>
        <div className="">
          <span
            className="box mx-6"
            style={{
              border: "1px solid yellow",
              backgroundColor: "#222221",
              marginTop: "100px",
            }}
          >
            <span className="level title has-text-white is-5">
              Connect your wallet to begin earning
            </span>
            <button
              className="button is-warning has-text-black"
              onClick={this.handleClick}
            >
              Connect your wallet
            </button>
          </span>
        </div></div>
      );
    } else if (this.state.isEligible === "isVerified") {
      lockModal = (
        <div>
          {this.state.isVerified ? (
            ""
          ) : (
            <div className="">
              <span
                className="box mx-6"
                style={{
                  border: "1px solid yellow",
                  backgroundColor: "#222221",
                  marginTop: "100px",
                }}
              >
                <span className="level title has-text-white is-5">
                  You must have a verified account in order to participate in
                  this campaign.{" "}
                </span>
                <button
                  className="button is-warning has-text-black"
                  onClick={this.handleClickVerify}
                >
                  Verify your account
                </button>
              </span>
            </div>
          )}
        </div>
      );
    } else if (this.state.isEligible === "isAsset") {
      lockModal = (
        <div>
          {myNonZeroAsas.includes(this.state.assetId) === true ? (
            ""
          ) : (
            <div className="">
              <span
                className="box mx-6"
                style={{
                  border: "1px solid yellow",
                  backgroundColor: "#222221",
                  marginTop: "100px",
                }}
              >
                <span className="level title has-text-white is-5">
                  You must own {this.state.assetName} in order to participate in
                  this campaign.{" "}
                </span>
              </span>
            </div>
          )}
        </div>
      );
    }

    var lockMobile;
    if (!localStorage.getItem("myAddress")) {
      lockMobile = (
        <div className="">
          <span
            className="box mx-2"
            style={{ border: "1px solid yellow", backgroundColor: "#222221" }}
          >
            <span className="level title has-text-white is-5">
              Connect your wallet to begin earning
            </span>
            <button
              className="button is-warning has-text-black"
              onClick={this.handleClick}
            >
              Connect your wallet
            </button>
          </span>
        </div>
      );
    } else if (this.state.isEligible === "isVerified" && this.state.isVerified !== 'yes') {
      lockMobile = (
        <div>
          {this.state.isVerified ? (
            ""
          ) : (
            <div className="">
              <span
                className="box mx-2"
                style={{
                  border: "1px solid yellow",
                  backgroundColor: "#222221",
                }}
              >
                <span className="level title has-text-white is-5">
                  You must have a verified account in order to participate in
                  this campaign.{" "}
                </span>
                <button
                  className="button is-warning has-text-black"
                  onClick={this.handleClickVerify}
                >
                  Verify your account
                </button>
              </span>
            </div>
          )}
        </div>
      );
    } else if (this.state.isEligible === "isAsset" && myNonZeroAsas.includes(this.state.assetId) === false ) {
      lockMobile =
        <div>
            <div className="">
              <span
                className="box mx-2"
                style={{
                  border: "1px solid yellow",
                  backgroundColor: "#222221",
                }}
              >
                <span className="level title has-text-white is-5">
                  You must own {this.state.assetName} in order to participate in
                  this campaign.{" "}
                </span>
              </span>
            </div>
        </div>
    } else {
      lockMobile = <div>{hasPosts}</div>;
    }

    var claimTheAsset;
    if (
      actualEarnings &&
      possibleEarnings &&
      myAsas.includes(this.state.assetId) &&
      this.state.totalClaimedForAsset < actualEarnings
    ) {
      claimTheAsset = (
        <div>
          {actualEarnings > this.state.totalClaimedForAsset ? (
            <button
              onClick={() => {
                this.sendThisAssetToAddress(
                  actualEarnings - this.state.totalClaimedForAsset
                );
              }}
              className={ !this.state.sendWait ? "ml-2 button is-small is-dark" : "is-loading ml-2 button is-small is-dark"}
            >
              {actualEarnings - this.state.totalClaimedForAsset > 0 ? (
                <span className="has-text-primary has-text-weight-semibold">
                  Claim {actualEarnings - this.state.totalClaimedForAsset}{" "}
                  {this.state.assetName}
                </span>
              ) : (
                "Claim"
              )}
            </button>
          ) : (
            <button className="ml-2 button is-small is-dark" disabled>
              Claim
            </button>
          )}
        </div>
      );
    } else if (this.state.sendWait) {
      claimTheAsset = (
        <button className="button is-dark is-small is-loading" disabled>
          Claiming {this.state.assetName}
        </button>
      );
    } else if (myAsas.includes(this.state.assetId === false)) {
      claimTheAsset = (
        <div>
          <button className="button is-dark is-small" disabled>
            Claim
          </button>
        </div>
      );
    } else {
      claimTheAsset = (
        <button className="button is-dark is-small" disabled>
          Claim
        </button>
      );
    }

    return (
        <div className="container mb-6 minh">
      <div
        className="columns mt-4 dark-primary"
        style={{ backgroundColor: "#0e0d0d" }}
      >
        <div className="column is-fixed box is-one-quarter" style={{ padding: "0px", backgroundColor: "#1a1a1a",borderRadius:"6px" }}>
          <div style={{ backgroundColor: "#1a1a1a" }}>
              <div className={`modal ${activeVerify}`}>
                <div class="modal-background"></div>
                <div class="modal-card">
                  <div class="">
                    <header className="modal-card-head">
                      <p className="modal-card-title">Verify your account</p>
                      <button
                        onClick={this.handleClickVerify}
                        className="delete"
                        aria-label="close"
                      />
                    </header>
                    <div className="modal-card-body">
                      <div className="field">
                        <label className="label">Your phone number</label>

                        <div class="field">
                          <p class="control" style={{ maxWidth: "300px" }}>
                            <PhoneInput
                              placeholder="Enter phone number"
                              initialValueFormat="national"
                              className="input"
                              labels={us}
                              defaultCountry="US"
                              value={this.state.phone}
                              onChange={(phone) => this.setState({ phone })}
                            />
                          </p>
                          <p className="control mt-2">
                            {this.state.loading === true ? (
                              <span className="button is-primary has-text-black is-loading">
                                Send verification
                              </span>
                            ) : (
                              <span
                                className="button is-primary has-text-black"
                                onClick={this.verifySMS}
                              >
                                Send verification
                              </span>
                            )}
                          </p>
                        </div>
                        {/* <div className="control">
                    <input
                      className="input"
                      type="text"
                      onChange={this.handleVerifyInput}
                      placeholder="Enter your mobile number"
                    />
                  </div> */}
                      </div>
                      {/* {isVerified} */}
                      <br />
                      {this.state.loading ? (
                        <InputCode
                          length={5}
                          label="Enter the 5 digit code sent to your device"
                          onComplete={(code) => {
                            this.checkVerifySMS(code);
                          }}
                        />
                      ) : (
                        ""
                      )}
                      <span className="mt-4 mb-2 has-text-bold">
                        {this.state.verifySuccess === true
                          ? "Your account was successfully verified"
                          : ""}{" "}
                        {this.state.verifyError
                          ? "There was an error. You should try again"
                          : ""}
                      </span>
                      <h3 className="mt-5 label mb-2">Why verify?</h3>
                      <p className="subtitle is-6 has-text-black">
                        While verifying your account is optional, some projects
                        require verified accounts to earn assets. We never
                        directly store, save, or share your phone number for{" "}
                        <u>any</u> purposes other than to verify that you have
                        access to the serviced device.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            <section
              className="hero is-dark"
              style={{
                backgroundImage: cover,
                backgroundSize: "cover",
              }}
            >
              

              <div className={`modal ${activeProf}`}>
                <div className="modal-background" />
                <div className="modal-card">
                  <header className="modal-card-head">
                    <p className="modal-card-title">Edit your profile</p>
                    <button
                      onClick={this.handleClickProf}
                      className="delete"
                      aria-label="close"
                    />
                  </header>
                  <section className="modal-card-body">
                    <div className="field">
                      <label className="label">Username</label>
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          onChange={this.handleUsernameChange}
                          defaultValue={this.state.username}
                          placeholder="Choose a username"
                        />
                      </div>
                    </div>
                    <div class="field">
                      <label className="label">Avatar</label>
                      <div style={{ width: "100px" }}>
                        {this.state.isUploading && (
                          <p className="has-text-black">Uploading...</p>
                        )}
                        {this.state.displayImage && (
                          <img src={this.state.displayImage} />
                        )}
                      </div>
                      <label
                        class="button"
                        style={{
                          backgroundColor: "#ebebeb",
                          color: "black",
                          padding: 10,
                          borderRadius: 4,
                          cursor: "pointer",
                        }}
                      >
                        Upload avatar
                        <FileUploader
                          hidden
                          accept="image/*"
                          name="displayImage"
                          randomizeFilename
                          storageRef={firebaseApp.storage().ref("images")}
                          onUploadStart={this.handleUploadStart}
                          onUploadError={this.handleUploadError}
                          onUploadSuccess={this.handleUploadSuccess}
                          onProgress={this.handleProgress}
                        />
                      </label>
                    </div>

                    <div class="field">
                      <label className="label">Cover Photo</label>
                      <div style={{ width: "300px" }}>
                        {this.state.isUploading && (
                          <p className="has-text-black">Uploading...</p>
                        )}
                        {this.state.coverImage && (
                          <img src={this.state.coverImage} />
                        )}
                      </div>
                      <label
                        class="button"
                        style={{
                          backgroundColor: "#ebebeb",
                          color: "black",
                          padding: 10,
                          borderRadius: 4,
                          cursor: "pointer",
                        }}
                      >
                        Upload cover photo
                        <FileUploader
                          hidden
                          accept="image/*"
                          name="coverImage"
                          randomizeFilename
                          storageRef={firebaseApp.storage().ref("images")}
                          onUploadStart={this.handleUploadStartCover}
                          onUploadError={this.handleUploadErrorCover}
                          onUploadSuccess={this.handleUploadSuccessCover}
                          onProgress={this.handleProgressCover}
                        />
                      </label>
                    </div>
                    <div class="field">
                      <label className="label">Project links</label>
                      <div className="control">
                        <textarea
                          className="textarea"
                          placeholder="Create a bio"
                          defaultValue={this.state.bio}
                          onChange={this.handleBioChange}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label">Links</label>
                      <LinkForm addLinkToState={this.addLinkToState} />
                      {hasLinks}
                    </div>
                  </section>
                  <footer className="modal-card-foot">
                    <button
                      className="button is-warning"
                      onClick={this.updateDB}
                    >
                      Save changes
                    </button>
                    <button onClick={this.handleClickProf} className="button">
                      Cancel
                    </button>
                  </footer>
                </div>
              </div>
              <div
                className="hero-body"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div>
                  <figure className="image is-64x64 is-square">
                    <img
                      className="border is-rounded flame"
                      alt="Avatar"
                      src={
                        this.state.displayImage
                          ? this.state.displayImage
                          : "https://media1.giphy.com/media/3o7bu3XilJ5BOiSGic/giphy.gif"
                      }
                    />
                  </figure>
                </div>
                <div style={{ marginLeft: "20px" }}>
                  <h1 className="title is-size-3	is-1">
                    {this.state.username ? (
                      <span>{this.state.username}</span>
                    ) : (
                      "Anonymous"
                    )}
                  </h1>
                  {/* <h2 className="subtitle">{this.state.algoAddress}</h2> */}
                  {/* <Task title={this.state.username} /> */}
                  {/* <p className="title is-3 has-text-weight-bold">
                  {this.state.username}
                </p> */}
                  {isConnected}
                  {/* {connected} */}
                  {/* <p className="button is-small is-dark">
                {this.state.myAddress ? (
                  <p>
                    <a onClick={this.handleConnect}> {this.state.myAddress} </a>
                  </p>
                ) : null}{" "}
              </p> */}
                </div>
              </div>
            </section>
            <section className="p-4">
              <div className="container">
                {/* <h3 className="subtitle is-6">Wallet: {this.state.myAddress}</h3> */}

                <div className="column level">
                  <div className="level-left">
                    <h1
                      className="title is-4 level-item"
                      style={{ display: "inline-flex" }}
                    >
                      <span
                        className="has-text-white"
                        style={{ fontFamily: "" }}
                      >
                        Project Overview
                      </span>
                      {/* <span
                    className="button is-small is-text level-item"
                    onClick={this.handleClick}
                  >
                    {" "}
                    edit profile
                  </span> */}
                    </h1>
                  </div>
                  <p style={{ marginTop: "10px", color: "#ebebeb" }}>
                    {this.state.projectInfo
                      ? this.state.projectInfo
                      : "No overview provided"}
                  </p>

                  <br />
                  <p className="subtitle is-5 is-size-6-mobile has-text-weight-semibold has-text-white">
                    <span style={{ fontFamily: "" }}>Project Links</span>
                  </p>
                  <div style={{ marginTop: "-15px" }}>
                    <p style={{ color: "#ebebeb" }}>
                      {/* <Input value="Click here to edit this text." /> */}
                      {this.state.links ? this.state.links : ""}
                      {hasLinksDisplay}
                      <a
                        className="button is-small is-text is-dark mt-2"
                        href={
                          "https://algoexplorer.io/asset/" + this.state.assetId
                        }
                      >
                        <img
                          style={{
                            width: "12px",
                            verticalAlign: "baseline",
                            marginRight: "9px",
                          }}
                          src="https://betanet.algoexplorer.io/icons/apple-touch-icon.png?v=7k4O26MvEA"
                        />
                        AlgoExplorer
                        <img
                          style={{
                            width: "10px",
                            verticalAlign: "baseline",
                            marginLeft: "5px",
                          }}
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/OOjs_UI_icon_external-link-ltr-invert.svg/2048px-OOjs_UI_icon_external-link-ltr-invert.svg.png"
                        />
                      </a>
                      <br />
                      <a
                        className="button is-small is-text is-dark mt-2"
                        href={
                          "https://tinychart.org/asset/" + this.state.assetId
                        }
                      >
                        <img
                          style={{
                            width: "12px",
                            verticalAlign: "baseline",
                            marginRight: "9px",
                          }}
                          src="https://tinychart.org/_next/image?url=%2Fasa-list%2F378382099%2Ficon.png&w=3840&q=75"
                        />
                        Tinychart
                        <img
                          style={{
                            width: "10px",
                            verticalAlign: "baseline",
                            marginLeft: "5px",
                          }}
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/OOjs_UI_icon_external-link-ltr-invert.svg/2048px-OOjs_UI_icon_external-link-ltr-invert.svg.png"
                        />
                      </a>
                      <br />
                      <a
                        className="button is-small is-text is-dark mt-2"
                        href={
                          "https://app.tinyman.org/#/swap?asset_in=0&asset_out=" +
                          this.state.assetId
                        }
                      >
                        <img
                          style={{
                            width: "12px",
                            verticalAlign: "baseline",
                            marginRight: "9px",
                          }}
                          src="https://algorand-developer-portal.tryprism.com/static/ecosystem-project-logos/2021/09/29/tinyman.png?w=160&cmd=resize_then_crop&height=160&quality=70"
                        />
                        Buy on Tinyman
                        <img
                          style={{
                            width: "10px",
                            verticalAlign: "baseline",
                            marginLeft: "5px",
                          }}
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/OOjs_UI_icon_external-link-ltr-invert.svg/2048px-OOjs_UI_icon_external-link-ltr-invert.svg.png"
                        />
                      </a>
                    </p>
                  </div>

                  {/* <h2>{this.state.net}</h2> */}
                  <br />
                  <div>
                    <p className="title is-5 has-text-weight-semibold has-text-white">
                      <span style={{ fontFamily: "" }}>Opt-in</span>
                    </p>
                    <p className="subtitle is-6" style={{ color: "#ebebeb" }}>
                      In order to earn {this.state.assetName}, you must first
                      opt-in to the asset.
                      <span
                        className="text is-size-7"
                        style={{ color: "#ebebeb" }}
                      >
                        {" "}
                        (Asset ID: {this.state.assetId})
                      </span>
                    </p>
                    {myAsas.includes(this.state.assetId) === false ? (
                      localStorage.getItem("myAddress") ? (
                        <button
                          onClick={
                            localStorage.getItem("walletconnect")
                              ? this.handleConnectOfficialOptIn
                              : this.handleConnectOptIn
                          }
                          className="button is-dark is-small"
                        >
                          Opt in
                        </button>
                      ) : (
                        <button
                          className="button is-small is-dark"
                          onClick={this.handleClick}
                        >
                          Connect wallet to opt in
                        </button>
                      )
                    ) : (
                      <button className="button is-small is-dark" disabled>
                        🟢 You are opted-in
                      </button>
                    )}
                  </div>
                  <br />

                  <br />
                  {/* <button className="button" onClick={this.handleConnect}>
                  Click to Connect
                </button>
                <br></br>
                <br></br>
                <button className="button" onClick={this.handleSend}>
                  Click to Send Transaction
                </button> */}
                  {/* <br></br>
              <button onClick={this.fetchBalance}>
                Click to check balance
              </button> */}
                  <br></br>
                  {/* <p>{"Connected Address: " + this.state.myAddress}</p> */}
                  <br></br>
                  {/* <p className="title is-6">
                  {"Balance: " + this.state.balance}{" "}
                  <span className="subtitle is-6">
                    <a href="#" className="link" onClick={this.fetchBalance}>
                      check balance
                    </a>
                  </span>
                </p> */}
                  {/* <p className="subtitle is-6">
                  {"Transaction ID: " + this.state.txID}
                </p> */}
                </div>
                <div style={{ marginTop: "-80px" }}>
                  {/* <h1 className="title is-4">NFT Gallery</h1> */}
                  {/* <Gal address={this.state.algoAddress} /> */}
                  {/* <NftGal address={this.state.algoAddress}/> */}

                  {/* <Gallery address={this.state.algoAddress} /> */}
                  {/* <select className="select" onChange={this.colorPicker}>
                <option value="red">red</option>
                <option value="blue">bloo</option>
              </select>
   
              <p className="control">
                <button onClick={this.updateDB} className="button">
                  Update
                </button>
              </p> */}
                </div>
                <div className={`modal ${active}`}>
                  <div class="modal-background"></div>
                  <div class="modal-card">
                    <div class="">
                      <header className="modal-card-head">
                        <p className="modal-card-title">Connect your wallet</p>
                        <button
                          onClick={this.handleClick}
                          className="delete"
                          aria-label="close"
                        />
                      </header>
                      <div className="modal-card-body">
                        <div className="buttons">
                          <button
                            class="button is-large"
                            onClick={this.handleConnectOfficial}
                          >
                            <span class="icon is-medium">
                              <img src="https://dl.airtable.com/.attachmentThumbnails/263ef9f20a2f2773a0d35ad2be9b5ade/b80ce5fe" />
                            </span>
                            <span>Algorand Wallet</span>
                          </button>
                          <button
                            class="button is-large"
                            onClick={this.handleConnect}
                          >
                            <span class="icon is-medium">
                              <img src="https://ml.globenewswire.com/Resource/Download/abe9938b-0ff0-4d09-8f8e-da43ca142899?size=2" />
                            </span>
                            <span>myAlgo Wallet</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        {/* <div className="column container">
        <div className="">
        <h1 className="title">Make a post</h1>
      <MDEdit/>
      </div>
      </div> */}
        <div className="column is-hidden-mobile ml-2">
          {/* {this.getOwnPropertyNames(this.state.posts).map((post, i) => (
          <ul>
              <div className="card mb-4">      
              <div className="card-content">
                <p className="subtitle">{post}</p>
                 <MDEditor.Markdown source={post.value} />
               </div>
             </div></ul>
      ))} */}
          {/* <MDEdit address={this.state.algoAddress}/> */}
          {optInTop}
          {/* {myAsas.includes(this.state.assetId) ? "" : <div className="message-content level" style={{color:"#fff",border:"1px solid yellow",borderRadius:"3px", padding:"10px 15px"}}>⚠️ You must opt in to asset {this.state.assetId} before claiming your earned {this.state.assetName}.<button className="button is-small is-warning" onClick={localStorage.getItem('walletconnect') ? this.handleConnectOfficialOptIn : this.handleConnectOptIn}>Opt in</button></div>} */}
          <p
            className="is-6 title has-text-grey-light mb-4"
          >
            Earn {this.state.assetName} by completing the following tasks:
          </p>
          {localStorage.getItem("myAddress") ? hasPosts : <div>{lockModal}
          <br />
          {actualEarnings === possibleEarnings ? (
            <span className="" style={{ color: "#d3d3d3" }}>
              <i>
               🎉 All of your tasks are completed. Find another campaign or check back later for more opportunities to
                earn {this.state.assetName}.
              </i>{" "}
            </span>
          ) : (
            ""
          )}
        </div>}</div>
        <div className="column is-narrow is-one-quarter">
          <div
            className="card"
            style={{
              position: "sticky",
              backgroundColor: "#1c1c1c",
            }}
          >
            <header className="card-header">
              <p
                className="card-header-title"
                style={{ backgroundColor: "#141414" }}
              >
                <span style={{ color: "white", whiteSpace: "nowrap" }}>
                  Task completion
                </span>{" "}
                <progress
                  class="ml-3 progress is-success is-small"
                  value={Object.keys(this.state.completedTasks).length}
                  max={Object.keys(this.state.tasks).length}
                ></progress>
              </p>
            </header>
            <div className="card-content">
              <div className="content" style={{ color: "#ebebeb" }}>
                <p>{hasTasks}</p>
                <div className="card-footer">
                  <div className="mt-4">
                    <p
                      className="is-inline is-justify-content-start has-text-bold title is-5 mt-4 has-text-white"
                      style={{ fontFamily: "" }}
                    >
                      Total earnings
                    </p>
                    <p
                      className="is-flex level subtitle is-6 mt-2"
                      style={{ color: "#ebebeb" }}
                    >
                      <span className="mr-2" style={{ whiteSpace: "nowrap" }}>
                        {actualEarnings ?? "?"} of {possibleEarnings | "?"}{" "}
                        {this.state.assetName}
                      </span>{" "}
                      {this.state.totalClaimedForAsset < possibleEarnings
                        ? claimTheAsset
                        : ""}
                    </p>
                    {youClaimed}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="column is-hidden-tablet is-hidden-desktop section">
          {localStorage.getItem("myAddress") ? (
            <div className="mr-2 ml-2">
              <div
                className="card"
                style={{ backgroundColor: "#1c1c1c" }}
              ></div>

              <p className="is-4 mb-4 has-text-white">
                Complete the following tasks to earn {this.state.assetName}
              </p>
              {myAsas.includes(this.state.assetId) === false ? (
                <div
                  className="message-content level-item"
                  style={{
                    color: "#fff",
                    border: "1px solid yellow",
                    borderRadius: "3px",
                    padding: "10px 15px",
                    marginBottom: "30px",
                  }}
                >
                  {" "}
                  You must opt in to asset {this.state.assetId} before claiming
                  your earned {this.state.assetName}.
                  <button
                    className="button level-item is-small is-warning"
                    onClick={this.handleConnectOptIn}
                  >
                    Opt in
                  </button>
                </div>
              ) : (
                ""
              )}
              <div>{lockMobile}</div>
            </div>
          ) : (
            <div className="">
              <span
                className="box mx-6"
                style={{
                  border: "1px solid yellow",
                  backgroundColor: "#222221",
                  marginTop: "10px",
                }}
              >
                <span className="level title has-text-white is-5">
                  Connect your wallet to begin completing tasks
                </span>
                <br />
                <button
                  className="button mt-4 is-small is-warning has-text-black"
                  onClick={this.handleClick}
                >
                  Connect your wallet
                </button>
              </span>
            </div>
          )}
        </div>
      </div></div>
    );
  }
}

export default (props) => <Project {...props} params={useParams()} />;
