import React from "react";

const Tooltip = ({ description, source, direction, className, size, tipsize }) => {
  const getDirection = () => {
    const appendedClass = className ? className : "";
    switch (direction) {
      case "top":
      case "right":
      case "left":
        return `${direction} ${appendedClass}`;
      default:
        return `bottom ${appendedClass}`;
    }
  };
  return (
    <div className={`tooltip-wrapper ${getDirection()}`}>
      <img
        alt="badge"
        src={source}
        style={{ height: size || "55px", marginRight: "10px", marginTop: "-5px" }}
      />
      <div className="description"
      style={{ width: tipsize || "100px", zIndex:"999"}}
      >{description}</div>
    </div>
  );
};

export default Tooltip;
