import React from "react";
import { Link } from "react-router-dom";
import "../main.css";
import firebaseApp from "../firebase";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

export default class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      myAddress: "Connect your wallet",
      searchInput: "",
      searchAddress: "",
      sendWait: true,
      color: "none",
      balance: "n/a",
      isFocused: false,
      username: "Anonymous",
      bio: "No bio provided",
      background:
        "https://firebasestorage.googleapis.com/v0/b/algrof-f67fb.appspot.com/o/blank-prof.jpg?alt=media&token=a7e4511d-5f91-4010-8a20-0604ee3af6d2",
      isModal: false,
      algoAddress: "",
      projects: {
        displayImage: "1",
      },
      users: {
        displayImage: "1",
      },
      selectedTab: 0,
    };
  }

  handleOnSearch = (string, results) => {
      this.setState({searchAddress : string})
  };
  

  //   handleOnHover = (result) => {
  //     console.log(result);
  //   };

  handleOnSelect = (item) => {
    console.log(item);
    window.location.href = "/u/" + item.myAddress;
  };

  handleOnGo = (item) => {
    console.log(item);
    window.location.href = "/u/" + item.myAddress;
  };

  

  handleOnFocus = () => {
    // this.setState({isFocused: !this.state.isFocused})
  };

  handleOnClear = () => {
  };


  formatResult = (item) => {
    return <p className="py-1">{item || "Anonymous"}</p>;  
  };

  dbRef = firebaseApp.database();

  componentDidMount() {
    let ref = firebaseApp.database().ref("/users/");
    ref.on("value", (snapshot) => {
      const users = snapshot.val();
      this.setState({ users });
      this.setState({ myAddress: users.myAddress });
      this.setState({ username: users.username });
    });
  }

  render(item) {
    const result = Object.keys(this.state.users).map(
      (key) => this.state.users[key]
    );

    return (
      // <div style={ this.state.isFocused ? {width: "400px"} : {width:'100px'}}>
      <div className="is-inline-flex field has-addons">          
        <ReactSearchAutocomplete
          items={result}
          placeholder="Paste an address or search for a user"
          fuseOptions={{ keys: ["myAddress", "username"] }} // Search on both fields
          
          resultStringKeyName={"username"} // String to display in the results
          onSearch={this.handleOnSearch}
          // onHover={this.handleOnHover}
          onSelect={this.handleOnSelect}
          onFocus={this.handleOnFocus}
          onClear={this.handleOnClear}
          formatResult={this.formatResult}
          showIcon={true}
          styling={{
            height: "34px",
            border: "",
            borderRadius: "4px",
            backgroundColor: "#171717",
            boxShadow: "none",
            hoverBackgroundColor: "#4f4f4f",
            color: "white",
            fontSize: "14px",
            fontFamily: "Inconsolata",
            iconColor: "turquoise",
            lineColor: "white",
            placeholderColor: "lightgrey",
            clearIconMargin: "3px 8px 0 0",
          }}
        />
                       <span class="control">
                      <a
                        href={"/u/" + this.state.searchAddress}
                      >
                          {this.state.searchAddress ?
                        <button className="is-size-7 letterspacing button is-primary has-text-weight-bold has-text-black" style={{height:"34px", fontFamily:"Inconsolata"}}>GO</button>
                       : <button disabled className="is-size-7 letterspacing button is-primary has-text-weight-bold has-text-black" style={{height:"34px", fontFamily:"Inconsolata"}}>GO</button>}</a>
                    </span>
      </div>
    );
  }
}
