import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer class="footer" style={{backgroundColor:"#222221",zIndex:"999999"}}>
      <div class="content has-text-centered">
        <p>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/algrof-f67fb.appspot.com/o/ski-logo-print.png?alt=media&token=bdf510d9-a101-49f7-92bd-f57fa30e1936"
            alt="Algo.ski"
            width="16"
            style={{ marginRight: "6px", marginBottom: "-3px" }}
          />
          <strong style={{color:"white"}}>algo.ski</strong>
          <p className="subtitle is-7" style={{color:"#ebebeb"}}>
            SKIMC7LGX3UGTFGP4QI57T3EALHT565K3SWF23IJHJGKP3JW5CXLFHNQFQ
          </p>
          </p>
          <a className="button is-text is-dark is-small" href="mailto:hello@algo.ski">contact</a>  <Link to="/roadmap"><span className="button is-dark is-small is-text">
            roadmap
          </span></Link>
<p>
  <p className="mt-3 subtitle is-size-7">Copyright © algo.ski 2022</p>
  <p className="subtitle is-size-7" style={{marginTop:"-10px"}}>Austin, TX</p>

        
        </p>
      </div>
    </footer>
  );
}
