import React from "react";
import firebaseApp from "firebase/compat/app";

export class LinkForm extends React.Component {
  state = {
    linkList: {
    linkSite: "",
    linkUrl: ""}
  };

  dbRef = firebaseApp.database();
  updateDB = this.updateDB.bind(this);

  updateDB() {
    const myAddress = this.state.algoAddress;
    const linkList = this.state.linkList;

    this.dbRef
      .ref("/users/")
      .child(myAddress)
      .set({linkList });
    this.setState({ isModal: !this.state.isModal });
  }

  handleLinkChange = event => {
    event.persist();
    console.log(event.target.name);
    // this.setState({ linkSite: event.target.value });
    this.setState(prevState => ({
      linkSite:
        event.target.name === "linkSite"
          ? event.target.value
          : prevState.linkSite,
      linkUrl:
        event.target.name === "linkUrl" ? event.target.value : prevState.linkUrl
    }));
  };
  
  handleLinkSubmission = event => {
    event.preventDefault();
    console.log(this.state.linkSite);
    console.log(this.state.linkUrl);
    this.props.addLinkToState(this.state.linkSite, this.state.linkUrl);
    this.setState(prevState => ({
      linkSite: "",
      linkUrl: ""
    }));
  };

  render() {
    return (
      <div className="is-flex is-align-items-center flex-wrap" style={{display:""}}>
        <form className="field" onSubmit={this.handleLinkSubmission}>
          <div className="control">
            <label className="label is-6 has-text-grey" for="linkSite">Site:</label>
            <input
              type="text"
              className="input"
              id="linkSite"
              placeholder="Twitter"
              onChange={this.handleLinkChange}
              value={this.state.linkSite}
              name="linkSite"
              required
            />
          </div>
          <div className="control">
            <label  className="label is-6 has-text-grey" for="linkUrl">Url:</label>
            <input
              type="text"
              className="input"
              id="linkUrl"
              name="linkUrl"
              placeholder="https://twitter.com/algodotski"
              onChange={this.handleLinkChange}
              value={this.state.linkUrl}
              required
            />
          </div>
          <div className="control">

<button type="submit" className="button is-small is-warning">
  Add link
</button>
</div>
        </form>

      </div>
    );
  }
}

export const LinkList = props => {
  const emptyList = length => {
    if (length === null) {
      return (
        <tr style={{ "text-align": "center" }}>
          <td colSpan="3">No links here</td>
        </tr>
      );
    }
  };

  const deleteActionItemFromState = index => () => {
    props.deleteActionItemFromState(index);
  };

  return (
    <div className="">
      <table className="table is-fullwidth">
        <thead>
          <tr>
            <th className="subtitle is-6 has-text-black">Site</th>
            <th className="subtitle is-6 has-text-black">Link</th>
            <th className="subtitle is-6 has-text-black">  </th>
          </tr>
        </thead>
        <tbody>
          {emptyList(props.actionItemsList.length)}
          {props.actionItemsList.map((linkSite, i) => (
            <tr key={i + 1}>
              <td>{linkSite.linkSite}</td>
              <td>{linkSite.linkUrl}</td>
              <td>
                <button
                  type="button"
                  className="button is-small"
                  onClick={deleteActionItemFromState(i)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};