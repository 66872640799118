import React, { useState } from 'react';

// import { quizzes } from '../data/quizzes';

import './Question.css';

const Question = ({
  currentQuiz,
  currentQuestion,
  currentAnswers,
  quizzes,
  incrementAttemptedQuestions,
  incrementScore,
  nextQuestion,
  completeQuiz,
}) => {
  const [selected, setSelected] = useState();
  const [selectedMessage, setSelectedMessage] = useState();

  let activeQuestion = quizzes[currentQuiz].questions[currentQuestion];
  let activeQuiz = quizzes[currentQuiz];

  const handleClick = (e) => {
    setSelected(e.target.value);
    incrementAttemptedQuestions();

    if (e.target.value === activeQuestion.correctAnswer) {
      incrementScore();
      setSelectedMessage('Correct!');
    } else {
      setSelectedMessage('Incorrect...');
    }
  };

  const handleSelected = (answer) => {
    if (selected === answer && selected === activeQuestion.correctAnswer) {
      return 'correct';
    } else if (
      selected === answer &&
      selected !== activeQuestion.correctAnswer
    ) {
      return 'incorrect';
    } else if (answer === activeQuestion.correctAnswer) {
      return 'correct';
    }
  };

  const nextButton = () => {
    setSelected();
    if (currentQuestion === activeQuiz.questions.length - 1) {
      completeQuiz();
    } else {
      nextQuestion();
    }
  };

  return (
    <>
      <div className="container">
        <p className="title has-text-white has-text-weight-medium is-5">{activeQuestion.text}</p>
        <ul className="mb-5 list">
          {currentAnswers.map((answer) => (
            <button
              className={`button is-dark mr-2 mb-2 ${selected && handleSelected(answer)}`}
              onClick={handleClick}
              value={answer}
              key={answer}
              disabled={selected}
            >
              <li className={`answer ${selected && handleSelected(answer)}`}>
                <span className="has-text-primary has-text-weight-bold">&nbsp;{answer}</span>
              </li>
            </button>
          ))}
        </ul>
      </div>

      <div className="">
        {/* <div>
          {selected ? (
            <p className="">{selectedMessage}</p>
          ) : null}
        </div> */}

        <div>
          {selected ? (
            <button className="mb-4 button has-text-black is-primary" onClick={nextButton}>
              Next question
            </button>
          ) : <button disabled className="mb-4 button has-text-black is-primary" onClick={nextButton}>
          Select an answer
        </button>}
        </div>
      </div>
    </>
  );
};

export default Question;
