import React, { useEffect, useRef } from "react"

export interface Emoji {
	label: string
	char: string
}

let DEFAULT_EMOJI = [
	{ label: "Stawberry", char: "🍓" },
	{ label: "Hot Pepper", char: "🌶️" },
	{ label: "Rocket", char: "🚀" },
	{ label: "Heart Eyes", char: "😍" },
	{ label: "Exploding Head", char: "🤯" },
	{ label: "Loudly Crying Face", char: "😭" },
]

export interface EmojiPickerProps {
	selectedEmojis: string[]
	onClose: () => void
	onToggleEmoji: (
		emoji: string,
		event: React.MouseEvent<HTMLDivElement>,
	) => void
}

export default function EmojiPicker(props: EmojiPickerProps) {
	let ref = useRef<HTMLDivElement>(null)
	let emojis = DEFAULT_EMOJI

	useEffect(() => {
		function onClick(event: MouseEvent) {
			if (event.target instanceof Node && !ref.current?.contains(event.target)) {
				props.onClose()
			}
		}
		window.addEventListener("click", onClick)
		return () => {
			window.removeEventListener("click", onClick)
		}
	})

	return (
		<div
			ref={ref}
		>
			{emojis.map(emoji => {
				let selected = props.selectedEmojis.includes(emoji.char)
				return (
                    <div style={{zIndex:"999"}}>
					<div
                    style={{display:"inline-block"}}
						key={emoji.char}
                        className="mr-1 tag is-small is-dark"
						aria-label={emoji.label}
						onClick={event => {
							props.onToggleEmoji(emoji.char, event)
						}}
					>
						<span>{emoji.char}</span>
					</div>
                    </div>
				)
			})}
		</div>
	)
}
