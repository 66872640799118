import * as React from "react";
import { useState, useEffect, FC } from "react";
import LazyLoad, { forceCheck } from "react-lazyload";
import {
  getAssetsOfAddress,
  getCollectionFromTokens,
  getDetailsOfAsset,
  getDetailsOfAssets
} from "../lib/algohelpers";
import { NFT } from "../lib/nft";
import { useParams } from "react-router-dom";
import Masonry from "react-masonry-css";
import GhostCard from "./GhostCard";
import { useWindowDimensions } from "../utils";
import "../main.css";

const getStorageItem = (f: string, def: string) => {
  return JSON.parse(localStorage.getItem(f) ?? def);
};
const setStorageItem = (f: string, val: any, def: any) => {
  localStorage.setItem(f, JSON.stringify(val) ?? def);
};

interface IProps {
  address: 'na',
  redo: 'redo'
}

const Gallery: FC<IProps> = ({ address, redo }: IProps): JSX.Element => {
  const [update, setUpdate] = useState<boolean>(false);
  const [accs, setAccs] = useState<string[]>(getStorageItem("accounts", "[]"));
  const [assets, setAssets] = useState<any[]>([]);
  const [nfts, setNfts] = useState<NFT[]>([]);
  const [fetched, setFetched] = useState<boolean>(false);
  const [interrupt, setInterrupt] = useState<boolean>(false);

  const { width } = useWindowDimensions();

  const [accForm, setAccForm] = useState("");

  const handleScroll = (e: any) => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      setFetched(false);
      /* fetchNFTsOfN(5).then(() => {
          trigger = false;
        }); */
      setUpdate(true);

      console.log("Finding NFTs");
    }
  };
  const addAccount = () => {
    // TODO: Check address if valid
    setAccs((prevAccs) => [...prevAccs, accForm]);

    const cacheAccounts: string[] = getStorageItem("accounts", "[]");

    setAccForm("");
  };

  useEffect(() => {
    addAccount();
  }, []);

  



  const breakpointColumnsObj = {
    default: 4,
    1500: 3,
    900: 2,
    700: 2,
    421: 1
  };

  const fetchNFTsOfN = async (n: number, fAssets?: any[]) => {
    const targetAssets = fAssets ?? assets;

    if (targetAssets.length === 0){
      console.log("Assets length is 0");
      setFetched(true);
      return;
    }

    console.log(targetAssets.slice(0, n));
    const newNfts = await getDetailsOfAssets(targetAssets.slice(0, n));

    setNfts((prevNfts) => [...prevNfts, ...newNfts]);

    setAssets(targetAssets.slice(n));

    const newMds = newNfts.map((n: NFT) => ({
      id: n.assetId,
      name: n.name,
      url: n.url,
      creator: n.creator,
      manager: n.owner,
      total: n.count
    }));
    setFetched(true);
  };

  useEffect(() => {
   
      fetchNFTsOfN(20);
      setUpdate(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  useEffect(() => {
    const fetchAssets = async () => {
      let fetchedAssets: any[] = [];
      for (let i = 0; i < accs.length; i++) {
        fetchedAssets = [
          ...fetchedAssets,
          ...(await getAssetsOfAddress(address))
        ];
      }
      setAssets(fetchedAssets);
      return fetchedAssets;
    };

    setNfts([]);
    setFetched(false);
    fetchAssets().then((fAssets) => fetchNFTsOfN(80, fAssets));

    return () => {
      // return a cleanup function to unregister our function since its gonna run multiple times
      window.removeEventListener("scroll", handleScroll);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accs]);

    var notFound;
    if (!fetched ){
      notFound=
      <div className="has-text-centered">
          <p className="is-size-5" style={{color:"white"}}>Finding NFTs..</p>
          <p className="subtitle is-size-6">Lots of NFTs? This may take up to a minute the first time..</p>
          <div className=""><img className="image is-128x128" style={{margin:"0 auto"}} src="https://www.skiresortsworldwide.com/img/loading.gif" /><div></div><div></div></div>
          {/* <img className="animatePulse image is-64x64" src="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/160/apple/81/right-pointing-magnifying-glass_1f50e.png"/>  */}
          </div>
    } else if (fetched && 1 > 2) {
      notFound =
      <h2 className="mt-6 is-size-5 is-centered has-text-centered" style={{color:"white"}}>
      <br/><br/>{accs.length > 0 ? 
      "argh" : "Errrrror"}
    </h2>      
    } else if (fetched && nfts.length === 0) {
      notFound =
      <h2 className="has-text-grey-light my-6 is-size-5 is-size-6-mobile is-centered has-text-centered has-text-left-mobile">
      {accs.length > 0 ? 
      "If this wallet has any NFTs, they'll show up here. It may take a minute." : "Errrrror"}
    </h2>}


    
  return (
    <div
      className="h-full w-screen overflow-y-scroll"
      onScroll={(e) => {
        console.log("Scrolling");
      }}
    >
      {/* <span className="py-10 pl-10 inline-flex w-full">
        <h1 className="syne text-5xl font-bold text-white">Your Collection</h1>
        <div className="ml-auto flex flex-row">
          <div className="px-4 ">
            {accs.map((acc: string) => (
              <button
                onClick={() => removeAccount(acc)}
                className="bg-indigo-700 text-white anaheim text-md rounded px-3 py-1 ml-2 hover:bg-red-600 transition-colors ease-in"
              >
                {acc.slice(0, 5) + "..." + acc.slice(48)}
              </button>
            ))}
          </div>
          <input
            className="w-15 pl-5 py-3 rounded-l-lg bg-white placeholder-gray-700 anaheim"
            placeholder="Add Wallet"
            value={accForm}
            onChange={handleAccForm}
          />
          <button
            onClick={addAccount}
            className="bg-indigo-700 rounded-r-lg py-3 px-3 mr-14 text-xl font-bol"
          >
            <span className="material-icons text-white align-middle">add</span>
          </button>
        </div>
      </span> */}
      {notFound}
      {/* {nfts.length === 0 && fetched && (
        <h2 className="mt-6 is-size-5 is-centered has-text-centered" style={{color:"white"}}>
          <br/><br/>{accs.length > 0 ? 
          "We couldn't find any NFTs in this wallet.. Try refreshing the page.." : "Errrrror"}
        </h2>
      )} */}

      <Masonry
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
        breakpointCols={breakpointColumnsObj}
      >
        {[
          ...nfts.map((nft) => (
            <div className="card" style={{ marginBottom: "15px",backgroundColor:"#3b3b3b" }}>
              <div className="card-header">
                <div className="card-header-title">
                  <h1>
                    <strong style={{color:"white"}}>{nft.name}</strong>
                  </h1>
                </div>
                <div className="card-header-icon">
                  <img
                    style={{ marginLeft: "5px", width: "14px" }}
                    alt="Verified asset"
                    src="https://cdn3.iconfinder.com/data/icons/essentials-volume-i/128/verified-blue-512.png"
                  />
                </div>
              </div>

              <div className="card-image">
                {nft.url.startsWith("ipfs") && (
                  <img
                    src={"https://ipfs.io/ipfs/" + nft.url.slice(7) || "https://miro.medium.com/max/1838/1*e_Loq49BI4WmN7o9ItTADg.gif"}
                    alt="NFT"
                  />
                )}
                {nft.url.startsWith("https") && <img src={nft.url || "https://miro.medium.com/max/1838/1*e_Loq49BI4WmN7o9ItTADg.gif"} alt="NFT" />}
                {/* <img src={nft.url} alt="NFT" /> */}
              </div>
              <div
                className="card-content"
                style={{ display: "flex", padding: "10px 0px 10px 10px" }}
              >
                <p className="content subtitle is-6">
                  {/* {nft.assetId} */}
                  <a
                    href={"https://www.nftexplorer.app/asset/" + nft.assetId}
                    className="button is-small is-dark is-text"
                    style={{ marginRight: "10px" }}
                  >
                    {" "}
                    View on NFT Explorer
                  </a>
                </p>
                {/* <p className="content subtitle is-6">
                  <a
                    href={"https://www.nftexplorer.app/asset/" + nft.assetId}
                    className="button is-small is-primary is-light"
                  >
                    {" "}
                    Sell it
                  </a>
                </p> */}
              </div>
            </div>
          )),

         
        ]}
      </Masonry>

      
    </div>
  );
};

export default Gallery;