import { render } from "@testing-library/react";
import React from "react";
import firebaseApp from "../../../firebase";

export const getQuizzes = () => {
  return new Promise((resolve) => setTimeout(resolve(quizzes), 2000));
};

export const getMoreQuizzes = () => {
  return new Promise((resolve) => setTimeout(resolve(moreQuizzes), 2000));
};

const quizzes = [
  {
    title: 'The Test Quiz',
    questions: [
      {
        text: 'Did you know the answer is One?',
        correctAnswer: 'One',
        incorrectAnswers: ['Two', 'Three', 'Four'],
      },
      {
        text: 'Ok, how about choosing Two?',
        correctAnswer: 'Two',
        incorrectAnswers: ['One', 'Three', 'Four'],
      },
      {
        text: 'You probably guessed it, the answer is Three',
        correctAnswer: "Three",
        incorrectAnswers: [
          "One",
          "Two",
          "Four",
        ],
      },
      {
        text: 'Just pick it',
        correctAnswer: "Four",
        incorrectAnswers: [
          "One",
          'Two',
          'Three',
        ],
      },
    ],
  },
  {
    title: 'Basics of CSS',
    questions: [
      {
        text: 'Which markup correctly assigns an HTML element a CSS class?',
        correctAnswer: "<div class='cat'>hi</div>",
        incorrectAnswers: [
          "<div className='cat'>hi</div>",
          "<div>hi</div className='cat'>",
          "<div class='cat'>hi</div class='cat'>",
        ],
      },
      {
        text:
          'Which markup is CSS that sets all cat classes to have pink font?',
        correctAnswer: '.cat { color: pink; }',
        incorrectAnswers: ['cat { color: pink; }', '<cat> color: pink; </cat>'],
      },
    ],
  },
];

const quizzes1 = [
  {
    title: 'The Test Quiz',
    questions: [
      {
        text: 'Did you know the answer is One?',
        correctAnswer: 'One',
        incorrectAnswers: ['Two', 'Three', 'Four'],
      },
      {
        text: 'Ok, how about choosing Two?',
        correctAnswer: 'Two',
        incorrectAnswers: ['One', 'Three', 'Four'],
      },
      {
        text: 'You probably guessed it, the answer is Three',
        correctAnswer: "Three",
        incorrectAnswers: [
          "One",
          "Two",
          "Four",
        ],
      },
      {
        text: 'Just pick it',
        correctAnswer: "Four",
        incorrectAnswers: [
          "One",
          'Two',
          'Three',
        ],
      },
    ],
  },
  {
    title: 'Basics of CSS',
    questions: [
      {
        text: 'Which markup correctly assigns an HTML element a CSS class?',
        correctAnswer: "<div class='cat'>hi</div>",
        incorrectAnswers: [
          "<div className='cat'>hi</div>",
          "<div>hi</div className='cat'>",
          "<div class='cat'>hi</div class='cat'>",
        ],
      },
      {
        text:
          'Which markup is CSS that sets all cat classes to have pink font?',
        correctAnswer: '.cat { color: pink; }',
        incorrectAnswers: ['cat { color: pink; }', '<cat> color: pink; </cat>'],
      },
    ],
  },
];

const moreQuizzes = [
  {
    title: 'Basics of JS',
    questions: [
      {
        text: 'Which declaration creates a variable in block scope?',
        correctAnswer: 'let power = 9001;',
        incorrectAnswers: [
          'var power = 9001;',
          'power = 9001;',
          'int power = 9001;',
        ],
      },
      {
        text: 'Which of the following is correct?',
        correctAnswer:
          'JavaScript is used in browsers, servers, and other applications.',
        incorrectAnswers: [
          'JavaScript is a universally respected and loved language.',
          'JavaScript is a completed language and will no longer change.',
          'JavaScript includes a syntax for declaring variable types.',
        ],
      },
    ],
  },
  {
    title: 'JavaScript Network Requests',
    questions: [
      {
        text: 'What is the purpose of an XMLHTTPRequest?',
        correctAnswer: 'To send an asynchronous request to a web endpoint',
        incorrectAnswers: [
          'To fetch XML from a local HTTP server.',
          'To receive a file input from the user.',
        ],
      },
      {
        text: 'Which API generally supersedes XMLHTTPRequest?',
        correctAnswer: 'fetch',
        incorrectAnswers: ['get', 'curl', 'async/await'],
      },
    ],
  },
];

// export class QuizData extends React.Component {
// getQuizData = () => {
//     let ref = firebaseApp.database().ref("/projects/" + this.state.algoAddress);
//     ref.on("value", (snapshot) => {
//       const projects = snapshot.val();
//       if(projects) { this.setState({
//         projects: true,
//         projects: projects.projectInfo,

//       })};
//     } 
//     ) 
// }}